/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import { useMutation, useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

// Otros
import api from '../services/api.services';
import { KEY_USERS, KEY_USER_UPDATE } from '../constants/auth.constant';

// Types
import { type BodyType, type IdType, type UseQueryResponse } from '../types/ReactQuery';
import { type CreateUserInterface, type UpdateUsersInterface, type UsersDeleteInterface } from './useUser.types';

/**
 * Descripcion del hook: La descripción de por qué estamos haciendo este hook y que resuelve.
 *
 * Implementacion: Descripción sobre cómo puedes implementar el hook.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

// Esta función se encarga de actualizar una oferta
export const useDeleteUser = (): UsersDeleteInterface => {
  const token: string | null = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const deletedUser = useMutation({
    mutationFn: async (id: IdType) => {
      return api.users.deleteUser(token, id);
    },

    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_USERS]);

      if (data.error) {
        toast.dismiss();
        console.error(data.message);
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message || 'Usuario eliminado correctamente');
      }
    },

    onError: (data: any) => {
      toast.dismiss();
      toast.error(data.message || data.error.message || 'Algo ha ocurrido');
    }
  });

  // Esta función recibe el identificador y los datos a actualizar de la oferta y ejecuta la mutación creada anteriormente para realizar la petición al servidor
  const handleDelete = async (id: IdType): Promise<void> => {
    deletedUser.mutate(id);
  };

  return { deletedUser, handleDelete };
};

export const useUpdateUser = (): UpdateUsersInterface => {
  const token: string | null = sessionStorage.getItem('token');

  const updatedUser = useMutation({
    mutationFn: async ({ id, body }: { id: IdType; body: BodyType }) => {
      return api.users.updateUser(token, id, body);
    },

    onSuccess: (data) => {
     
      if (data.error) {
        toast.dismiss();
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message || 'Usuario actualizado correctamente');
        
      }
    },

    onError: (data: any) => {
      toast.dismiss();
      toast.error(data.message || data.error.message || 'Algo ha ocurrido');
    }
  });

  const updateUser = async (id: IdType, body: BodyType): Promise<void> => {
    updatedUser.mutate({ id, body });
  };

  return { updatedUser, updateUser };
};


export const useCreateUser = (): CreateUserInterface => {
  const token: string | null = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const createdResponse = useMutation({
    mutationFn: async ({ body }: { body: BodyType }) => {
      return api.users.create(token, body);
    },

    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_USERS]);

      if (data.error) {
        toast.dismiss();
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message);
      }
    },

    onError: (data: any) => {
      toast.dismiss();
      toast.error(data.message || data.error.message || 'Algo ha ocurrido');
    }
  });

  const sendNewUSer = async (body: BodyType): Promise<void> =>
  { createdResponse.mutate({ body }); };

  return { createdResponse, sendNewUSer };
};

// Esta función permite obtener los datos de una oferta específica.
export const useUser = (id: IdType = '1'): Omit<UseQueryResponse, 'filters'> => {
  // Se obtiene el contexto de autenticación.
  const token: string | null = sessionStorage.getItem('token');

  // Se realiza la consulta a la API para obtener los datos de la oferta.
  const { data, isError, isLoading, error, refetch, isSuccess } = useQuery(
    [KEY_USERS, id],
    async () => await api.users.getOne(token, id)
  );

  // Se devuelven los datos obtenidos de la consulta a la API.
  return { data, isError, isLoading, error, refetch, isSuccess };
};

const useUsers = (): Omit<UseQueryResponse, 'filters'> => {
  const token: string | null = sessionStorage.getItem('token');

  const { data, isLoading, isError, isSuccess, refetch } = useQuery<unknown, unknown>(
    [KEY_USERS], // Esta clave y los filtros se pasan como parámetros a la consulta.
    async () => await api.users.getAllUsers(token) // Esta función asíncrona obtiene todas las ofertas usando el token de sesión y los filtros especificados anteriormente.
  );

  return { data, isLoading, isError, isSuccess, refetch };
};

export default useUsers;
