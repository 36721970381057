// Librerias
import React, { type ReactNode, createContext, useState } from 'react';

// Otros
import { type SidebarContextType } from '../types/SidebarContext';

/**
 * Descripcion del context: La descripción de por qué estamos haciendo este context y que resuelve.
 *
 * Implementacion: Descripción sobre cómo puedes implementar el context.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const initalValues = {
  sideOpen: false,
  userId: null,
  toggleSide: () => null,
  setUser: () => null,
  userAvatarImg: '', 
  setUserAvatarImg: () => '',
  firstName: '',
  setFirstName: () => '', 
  lastName: '',
  setLastName: () => ''
};

const SidebarContext = createContext<SidebarContextType>(initalValues);

export function SidebarProvider({ children }: { children: ReactNode }): JSX.Element {
  const [sideOpen, setSideOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [userAvatarImg, setUserAvatarImg] = useState<string | File>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const toggleSide = (value: boolean): void => {
    setSideOpen(value);
  };

  const setUser = (value: string | null): void => {
    setUserId(value);
  };
  

    

  const contextValue: SidebarContextType = {
    sideOpen,
    userId,
    toggleSide,
    setUser,
    userAvatarImg, 
    setUserAvatarImg, 
    firstName, 
    setFirstName, 
    lastName, 
    setLastName, 
  };


  return (
    <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>
  );
}

export default SidebarContext;
