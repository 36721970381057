// Esta función crea un slug a partir de una cadena de texto
const CreateSlug = (str: string): string => {
  // Elimina caracteres especiales
  const deleteSpecialCaracteres = /[^a-zA-Z\s]/g;
  // Elimina acentos
  const deleteAccents = /[\u0300-\u036f]/g;
  // Elimina espacios en blanco
  const deleteSpaces = / /g;
  // Reemplaza la letra ñ por n
  const deleteN = /ñ/gi;

  return (
    str
      // Normaliza la cadena de texto para eliminar caracteres especiales, acentos y demás.
      .normalize('NFD')
      // Reemplaza los caracteres especiales por vacío.
      .replace(deleteSpecialCaracteres, '')
      // Reemplaza los acentos por vacío.
      .replace(deleteAccents, '')
      // Reemplaza los espacios en blanco por guiones.
      .replace(deleteSpaces, '-')
      // Reemplaza la letra ñ por n.
      .replace(deleteN, 'n')
      // Convierte el texto a minúsculas.
      .toLowerCase()
  );
};

export default CreateSlug;
