/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */

// Librerias
import React, { type Key, useEffect, useState, type Dispatch } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { GrPowerReset } from 'react-icons/gr';
import { BsSquare, BsSquareFill } from 'react-icons/bs';
import { FaChevronDown, FaChevronRight, FaFilter } from 'react-icons/fa';
import { toast } from 'react-toastify';
import CheckboxTree from 'react-checkbox-tree';
import { Checkbox } from 'pretty-checkbox-react';

// Hooks
import { useSubCategories } from '../../hooks/useSubCategories';
import { useCategories } from '../../hooks/useCategories';

// Estilos
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import '@djthoms/pretty-checkbox';

// Props
interface Props {
  theme: string;
  setFilters: Dispatch<React.SetStateAction<FiltersInterface>>;
}
interface FiltersInterface {
  size: number;
  sort: string;
  order: string;
  categories?: string[];
  sub_categories?: string[];
  [key: string]: string | number | string[] | undefined;
}
interface Category {
  id: number;
  name: string;
  parent: number;
}
interface CheckboxChangeEvent {
  target: {
    checked: boolean;
    value: string;
  };
}
interface CategoryData {
  parent: number;
}
/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Filters = ({ theme, setFilters }: Props): JSX.Element => {
  // Estados
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);
  const [selectedTreeCategories, setSelectedTreeCategories] = useState<string[]>([]);
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  // Contextos

  // Hooks
  const categories = useCategories();
  const subCategories = useSubCategories();

  // Funciones
  // handle check normal categories
  const handleCategoryChange = (e: CheckboxChangeEvent): void => {
    const { value } = e.target;
    const isChecked: boolean | undefined = e.target.checked;

    if (isChecked) {
      setSelectedCategories((prev: string[]) => [...prev, value]);
    } else {
      setSelectedCategories((prev: string[]) =>
        prev.filter((cat: string) => cat !== value)
      );
    }
  };

  // handle check react-checkbox-tree
  const handleCheck = (checked: string[]): void => {
    setChecked(checked);
    setSelectedTreeCategories(checked.filter((cat: string) => !cat.includes('sub ')));
    setSelectedSubCategories(
      checked
        .filter((cat: string) => cat.includes('sub '))
        .map((cat: string) => cat.replace('sub ', ''))
    );
  };

  // handle clean filters
  const handleClean = (): void => {
    // Reset states
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setSelectedTreeCategories([]);
    setChecked([]);

    // Reset filters
    setFilters({ size: 20, sort: 'desc', order: 'created-at' });

    // uncheck all checkboxes
    const checkboxes =
      document.querySelectorAll<HTMLInputElement>('input[type=checkbox]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    toast.success('Los filtros han sido limpiados');
  };

  // search handle
  const setStringFilter = (key: string, value: string): void => {
    setFilters((prev: FiltersInterface) => {
      const newFilters = { ...prev };
      if (!value) {
        delete newFilters[key];
        return { ...newFilters };
      }
      return { ...newFilters, [key]: value };
    });
  };

  // create nodes for react-checkbox-tree
  const areaCategories = categories.data?.data.reduce(
    (acc: CategoryData[], cat: CategoryData) => {
      if (cat.parent === 1) {
        acc.push(cat);
      }
      return acc;
    },
    []
  );
  const nodes = areaCategories?.map((cat: { id: number; name: string }) => {
    const subCategoriesOfCat = subCategories.data?.data.filter(
      (subCat: { parent: number }) => subCat.parent === cat.id
    );

    const subCategoriesOfCatAsObjects = subCategoriesOfCat?.map(
      (subCat: { name: string; id: number }) => ({
        label: subCat.name,
        value: `sub ${subCat.id}`,
      })
    );

    return {
      label: cat.name,
      value: cat.id,
      children:
        subCategoriesOfCatAsObjects?.length > 0 ? subCategoriesOfCatAsObjects : undefined,
    };
  });

  // Hooks
  useEffect(() => {
    setFilters((prev: FiltersInterface) => {
      const mergedCategories = [...selectedCategories, ...selectedTreeCategories];
      const newFilters: FiltersInterface = { ...prev };

      if (!mergedCategories.length) {
        delete newFilters.categories;
      } else {
        newFilters.categories = mergedCategories;
      }

      if (!selectedSubCategories.length) {
        delete newFilters.sub_categories;
      } else {
        newFilters.sub_categories = selectedSubCategories;
      }

      return { ...newFilters };
    });
  }, [selectedCategories, selectedTreeCategories, selectedSubCategories]);

  return (
    <div className={`filters ${theme}`}>
      <div className='search'>
        <AiOutlineSearch size={20} />
        <input
          onChange={(e) => {
            setStringFilter('names', e.target.value);
          }}
          type='text'
          placeholder='Buscar por nombre'
        />
      </div>

      <div className='filtercontainer'>
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className='filter'
          name='filter'
        >
          <FaFilter size={18} />
        </button>
      </div>

      <div className={`filters__options ${open && 'active'}`}>
        {categories.isSuccess && (
          <>
            <div className='area container'>
              <h3>Área</h3>
              <CheckboxTree
                nodes={nodes}
                expanded={expanded}
                checkModel='all'
                noCascade
                checked={checked}
                onExpand={(expanded) => {
                  setExpanded(expanded);
                }}
                showNodeIcon={false}
                onCheck={(checked) => {
                  handleCheck(checked);
                }}
                icons={{
                  check: <BsSquareFill />,
                  uncheck: <BsSquare />,
                  halfCheck: <BsSquare />,
                  expandClose: <FaChevronRight />,
                  expandOpen: <FaChevronDown />,
                }}
              />
            </div>

            <div className='job container'>
              <h3>Tipo de trabajo</h3>
              {categories.data.data
                .filter((cat: Category) => cat.parent === 2)
                .map((cat: Category, index: Key) => (
                  <Checkbox
                    key={index}
                    value={cat.id}
                    onChange={handleCategoryChange}
                    name={cat.name}
                    id={cat.name}
                    shape='curve'
                    variant='fill'
                  >
                    {cat.name}
                  </Checkbox>
                ))}
            </div>
            {/* Comentado momentaneamente xq eliminaron filtro por seniority */}

            {/* <div className='seniority container'>
              <h3>Seniority</h3>
              {categories.data.data
                .filter((cat: { parent: number }) => cat.parent === 3)
                .map((cat: Category, index: Key | null | undefined) => (
                  <Checkbox
                    key={index}
                    value={cat.id}
                    onChange={handleCategoryChange}
                    name={cat.name}
                    id={cat.name}
                    shape='curve'
                    variant='fill'
                  >
                    {cat.name}
                  </Checkbox>
                ))}
            </div> */}

            <button
              title='Reiniciar filtros'
              className={`btn ${theme}`}
              onClick={handleClean}
            >
              Resetear Filtros
              <GrPowerReset />
            </button>
          </>
        )}
        {categories.isLoading && <p> Cargando filtros...</p>}
      </div>
    </div>
  );
};

export default Filters;
