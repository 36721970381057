// Librerias
import { useState } from 'react';

// Otros

/**
 * Descripcion del hook: Este hook se encarga de abrir y cerrar modales
 *
 * Implementacion: Descripción sobre cómo puedes implementar el hook.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

// Esta función devuelve un arreglo con dos elementos: el estado de un modal (mostrado o no) y una función para cambiar el estado.
const useModal = (): [boolean, () => void] => {
  // Usamos useState para mantener el estado del modal. Por defecto, el modal no se muestra.
  const [isShowing, setIsShowing] = useState(false);
  const { body } = document;

  // Esta función cambia el estado del modal, mostrándolo si estaba oculto y viceversa.
  const toggle = (): void => {
    setIsShowing(!isShowing);
    body.style.overflow = isShowing ? 'auto' : 'hidden';
  };

  // Devolvemos un arreglo con los dos elementos mencionados anteriormente.
  return [isShowing, toggle];
};

export default useModal;
