/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
// Librerias
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

// Contextos

// Hooks
import { useCreateOffer } from '../../hooks/useOffers';

// Componentes
import OffersForm from './OffersForm';
import { actualDate } from '../../helpers/Dates';
import Modal from '../Modal';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  theme: ThemeContextType['theme'];
  handleClick: () => void;
  isShowingModal: boolean;
}

/**
 * Descripción: Modal que contiene el formulario para hacer una oferta/busqueda
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const OffersModal = ({ isShowingModal, handleClick, theme }: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { newOffer, createOffers } = useCreateOffer();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: { value: false, label: '🔴 Desactivado' },
      end_date: actualDate(),
      title: '',
      categories: [],
      short_description: '',
      content: '<p>Contenido de la oferta.</p>',
    },
  });

  // Funciones

  // UseEffects
  useEffect(() => {
    if (newOffer.isLoading) {
      toast.dismiss();
      toast.loading('Enviando...');
    }

    if (newOffer.data) {
      reset();
      newOffer.reset();
    }
  }, [newOffer]);

  useEffect(() => {
    return () => {
      newOffer.reset();
    };
  }, []);

  // Renders
  return (
    <>
      <Modal show={isShowingModal} theme={theme} prompt={null}>
        <OffersForm
          theme={theme}
          errors={errors}
          control={control}
          register={register}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          createOffers={createOffers}
        />
      </Modal>
    </>
  );
};

export default OffersModal;
