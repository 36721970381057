// Librerias
import { BsSun } from 'react-icons/bs';
import { MdDarkMode } from 'react-icons/md';
import Img from 'react-cool-img';

// Contextos
import { type SidebarContextType } from '../../types/SidebarContext';
import { type ThemeContextType } from '../../types/ThemeContext';
import { type UseQueryResponse } from '../../types/ReactQuery';

// Hooks

// Componentes

// Imagenes
import Logo from '../../assets/images/icons/logo_small.png';
import LoaderImage from '../../assets/images/static/loader.gif';
import ErrorImage from '../../assets/images/icons/general__icon.png';
import { useContext, useEffect } from 'react';
import SidebarContext from '../../context/SidebarContext';

// Estilos

// Types
interface Props extends SidebarContextType {
  open: boolean;
  user: Record<string, unknown>;
}

type PartialProps = Omit<Props, 'userId'> &
  Pick<ThemeContextType, 'theme' | 'toggleTheme'> &
  Pick<UseQueryResponse, 'isError' | 'isLoading' | 'isSuccess'>;

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Header = ({
  theme,
  open,
  user,
  setUser,
  isError,
  sideOpen,
  isLoading,
  isSuccess,
  toggleSide,
  toggleTheme
}: PartialProps): JSX.Element => {
  // Estados

  // Contextos
  const { userAvatarImg, setUserAvatarImg } = useContext(SidebarContext);
  // Hooks

  // Funciones
  const handleTheme = (): void => {
    toggleTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const handleDropdown = (): void => {
    toggleSide(!sideOpen);
    if (user.id === 'number') {
      setUser(user.id);
    }
  };

  // UseEffects
  useEffect(()=>{
    setUserAvatarImg(userAvatarImg);
  }, [])

  // Renders
  return (
    <>
      <header className={`header ${theme}`}>
        <div className={`header__logo ${!open && 'active'}`}>
          <img src={Logo} alt='logo' />
          <h3>Human R.</h3>
        </div>

        <button onClick={handleTheme}>
          {theme === 'dark' ? <MdDarkMode /> : <BsSun />}
        </button>

        {/* <button>
          <MdOutlineHelp />
        </button> */}

        {/* <button>
          <BsFillBellFill />
        </button> */}

        {isLoading && (
          <button
            title='Loading user image'
            className='header__profile'
            onClick={handleDropdown}
          >
            <Img src={LoaderImage} alt='Avatar user' />
          </button>
        )}

        {isSuccess && (
          <button title='User image' className='header__profile' onClick={handleDropdown}>
            <Img
              // src={user.avatar}
              src={userAvatarImg === undefined ? user.avatar : userAvatarImg}
              error={ErrorImage}
              alt={user.firtsName}
              onClick={handleDropdown}
              placeholder={LoaderImage}
              title='Click para visualizar el perfil'
              cache={false}
            />
          </button>
        )}

        {isError && (
          <button
            title='Error loading user image'
            className='header__profile'
            onClick={handleDropdown}
          >
            <Img src={ErrorImage} alt='Avatar user' />
          </button>
        )}
      </header>
    </>
  );
};

export default Header;
