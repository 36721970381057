/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';

// Contextos

// Hooks

// Componentes
import { loginRequest } from '../../config/msalConfig';
import LoginForm from '../../components/Login/LoginForm';

// Imagenes
import Logo from '../../assets/images/icons/logo_small.png';

// Estilos

/**
 * Descripción: Este componente representa la pantalla de login y contiene el formulario
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Login = (): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { instance } = useMsal();

  // Funciones
  const handleLoginRedirect = (): void => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
    });
  };

  // UseEffects

  useEffect(() => {
    document.title = 'Inicia Sesión | Portinos';
  }, []);

  // Renders
  return (
    <>
      <section className='login'>
        {/* Logo */}
        <div className='login__logo'>
          <img src={Logo} alt='Portinos Logo' />
          <h1>Portinos</h1>
        </div>

        {/* Form */}
        <LoginForm handleLoginPopup={handleLoginRedirect} />

        {/* Copy */}
        <div className='login__copy'>
          <AiOutlineCopyrightCircle /> Portinos 2023
        </div>
      </section>
    </>
  );
};

export default Login;
