/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */

// Librerias
import React, { useContext, useState, useEffect } from 'react';
import { BiMessageSquareEdit, BiTrash } from 'react-icons/bi';
import Img from 'react-cool-img';

// Contextos
import SidebarContext from '../../context/SidebarContext';

// Hooks
import useUsers, { useDeleteUser } from '../../hooks/useUser';
import useProfile from '../../hooks/useProfile';
import useModal from '../../hooks/useModal';

// Componentes
import CreateUserForm from '../../components/Settings/CreateUserForm';
import UpdateUserForm from '../../components/Settings/UpdateUserForm';
import ModalDelete from '../../components/Modals/ModalDelete';

// Imagenes
import LoaderImage from '../../assets/images/static/loader.gif';
import ErrorImage from '../../assets/images/icons/general__icon.png';

// Estilos

// Types
interface UserType {
  id: string | null;
  avatar: File | string;
  firtsName: string;
  active: boolean;
  lastName: string;
  job: string;
  role: number;
}

/**
 * Descripción: Esta pagina renderiza a todos los usuarios administrativos
 *
 * Implementación: Se importa y ya.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Users = ({ theme }: { theme: string }): JSX.Element => {
  // Estados
  const [opensModalTo, setOpensModalTo] = useState<string>('create');
  const [userIdTo, seUserIdTo] = useState<string | null>(null);

  // Contextos
  const { toggleSide, userId, setUser } = useContext(SidebarContext);
  // Hooks
  const user: string | null = sessionStorage.getItem('userId');
  const { data, isLoading, isError, isSuccess } = useUsers();
  const { deletedUser, handleDelete } = useDeleteUser();
  const [isShowingModal, toggleModal] = useModal();
  const profile = useProfile(user);

  // Funciones
  const handleClick = (id: string | null): void => {
    toggleSide(true);
    setUser(id);
  };

  const handleModal = (forWhichOpen: string, id: string | null): void => {
    toggleModal();
    if (forWhichOpen === 'create') {
      setOpensModalTo('create');
    } else if (forWhichOpen === 'edit') {
      setOpensModalTo('edit');
      seUserIdTo(id);
    } else {
      setOpensModalTo('delete');
      seUserIdTo(id);
    }
  };

  // UseEffects
  useEffect(() => {
    if (deletedUser.data) {
      toggleModal();
      setOpensModalTo('create');
      deletedUser.reset();
    }
  }, [deletedUser]);

  useEffect(() => {
    !isShowingModal && setOpensModalTo('create');
  }, [isShowingModal]);

  // Renders
  return (
    <>
      {opensModalTo === 'create' && (
        <CreateUserForm
          theme={theme}
          handleClick={toggleModal}
          isShowingModal={isShowingModal}
        />
      )}

      {opensModalTo === 'edit' && (
        <UpdateUserForm
          theme={theme}
          handleClick={toggleModal}
          isShowingModal={isShowingModal}
          actualUserData={data.find((item: { id: null }) => item.id === userIdTo)}
        />
      )}

      {opensModalTo === 'delete' && (
        <ModalDelete
          toggleModal={toggleModal}
          isShowingModal={isShowingModal}
          isLoading={deletedUser.isLoading}
          title='¿Desea eliminar este usuario?'
          handleAction={async () => handleDelete(userIdTo)}
          subtitle='¿Está seguro que desea eliminar este usuario?'
        />
      )}

      <div className='users'>
        <div className='users__header'>
          <h2>Usuarios y administradores</h2>

          {profile.isSuccess && (
            <>
              {profile.data.role !== 2 && (
                <button
                  onClick={() => {
                    handleModal('create', null);
                  }}
                >
                  Crear usuario
                </button>
              )}
            </>
          )}
        </div>

        {isLoading && profile.isLoading && (
          <ul className='users__list'>
            <li className={`users__item `}>
              <Img src={LoaderImage} alt='user photo profile' />

              <div className='users__item__info'>
                <p>Cargando usuario...</p>
                <span>Rol: Cargando puesto...</span>
              </div>

              <div className='users__item__actions' />
            </li>
          </ul>
        )}

        {isError && (
          <ul className='users__list'>
            <li className={`users__item `}>
              <Img src={ErrorImage} alt='user photo profile' />

              <div className='users__item__info'>
                <p>Algo se rompio...</p>
                <span>Intente recargar la pagina.</span>
              </div>

              <div className='users__item__actions' />
            </li>
          </ul>
        )}

        {isSuccess && profile.isSuccess && (
          <ul className='users__list'>
            {data.map((user: UserType) => (
              <li
                key={user.id}
                className={`users__item ${
                  userId === user.id ? 'users__item__selected' : ''
                }`}
              >
                <Img
                  cache={false}
                  src={user.avatar}
                  error={ErrorImage}
                  alt='user photo profile'
                  placeholder={LoaderImage}
                  onClick={() => {
                    handleClick(user.id);
                  }}
                />

                <div
                  className='users__item__info'
                  onClick={() => {
                    handleClick(user.id);
                  }}
                >
                  <p
                    title={`El usuario ${user.firtsName} esta ${
                      user.active ? 'activo.' : 'inactivo.'
                    }`}
                  >
                    {`${user.firtsName} ${user.lastName}`} {user.active ? '🟢' : '🔴'}
                  </p>
                  <span>Rol: {user.job}</span>
                </div>

                <div className='users__item__actions'>
                  {profile.data.role !== 2 && user.role !== 0 && (
                    <div>
                      <button
                        onClick={() => {
                          handleModal('edit', user.id);
                        }}
                      >
                        <BiMessageSquareEdit />
                      </button>
                      <button
                        onClick={() => {
                          handleModal('delete', user.id);
                        }}
                      >
                        <BiTrash />
                      </button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Users;
