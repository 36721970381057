// Librerias
import React from 'react';

// Contextos

// Hooks

// Componentes

// Imagenes

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Privacy = (): JSX.Element => {
  // Estados

  // Contextos

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <section className='privacy'>
      <h1>
        Política de Privacidad para la Aplicación de Gestión de Recursos Humanos Portinos
      </h1>
      <p>
        En <b>Portinos</b>, reconocemos la importancia de la privacidad y la protección de
        datos personales. Por lo tanto, nos comprometemos a asegurar la confidencialidad y
        seguridad de la información recopilada a través de nuestra aplicación de gestión
        de recursos humanos. Esta política de privacidad describe cómo recopilamos,
        utilizamos, divulgamos y protegemos la información personal de los usuarios de
        nuestra aplicación. Al utilizar nuestra aplicación, usted acepta los términos y
        condiciones de esta política de privacidad.
      </p>
      <br />
      <h2>Recopilación de Información Personal:</h2>
      <p>
        Recopilamos información personal de los usuarios de nuestra aplicación con el fin
        de gestionar los recursos humanos de nuestra empresa de marketing digital. La
        información personal que podemos recopilar incluye, entre otros, nombre, dirección
        de correo electrónico, número de teléfono, dirección, historial laboral,
        habilidades y experiencia laboral, así como cualquier otra información relevante
        para la gestión de recursos humanos.
      </p>
      <br />
      <h2>Uso de la Información Personal:</h2>
      <p>
        Utilizamos la información personal recopilada a través de nuestra aplicación para
        llevar a cabo las siguientes actividades:{' '}
      </p>
      <p>
        <ol>
          <li>
            Gestión de recursos humanos: Utilizamos la información personal para gestionar
            el proceso de selección de candidatos, realizar evaluaciones de desempeño,
            gestionar la nómina y beneficios, y mantener registros de empleados.
          </li>

          <li>
            Comunicación: Utilizamos la información personal para comunicarnos con los
            empleados y solicitantes de empleo sobre oportunidades laborales,
            actualizaciones de políticas y procedimientos internos, y otras comunicaciones
            relacionadas con la gestión de recursos humanos.
          </li>

          <li>
            Mejora de la aplicación: Utilizamos la información personal para mejorar
            nuestra aplicación de gestión de recursos humanos, incluyendo la
            personalización de la experiencia del usuario, la optimización de la interfaz
            de usuario y la identificación de áreas de mejora.
          </li>
        </ol>
      </p>
      <br />
      <h2>Divulgación de la Información Personal:</h2>
      <p>
        No vendemos ni compartimos la información personal recopilada a través de nuestra
        aplicación con terceros, excepto en las siguientes circunstancias:
      </p>
      <p>
        <ol>
          <li>
            Proveedores de servicios: Podemos compartir información personal con
            proveedores de servicios que nos ayudan en la gestión de recursos humanos,
            como proveedores de servicios de nómina, proveedores de servicios de
            reclutamiento y selección, y proveedores de servicios de evaluación de
            desempeño.
          </li>
          <li>
            Cumplimiento legal: Podemos divulgar información personal en respuesta a una
            orden judicial, citación u otra solicitud legal válida, o para cumplir con
            cualquier otra obligación legal o normativa aplicable.
          </li>
        </ol>
      </p>
      <br />
      <h2>Seguridad de la Información Personal:</h2>
      <p>
        Tomamos medidas de seguridad adecuadas para proteger la información personal
        recopilada a través de nuestra aplicación contra la pérdida, uso indebido, acceso
        no autorizado, divulgación, alteración o destrucción no autorizada. Sin embargo,
        tenga en cuenta que ninguna transmisión de datos a través de Internet es
        completamente segura y que no podemos garantizar la seguridad absoluta de la
        información personal transmitida a través de nuestra aplicación.
      </p>
      <br />
      <h2>Retención de la Información Personal:</h2>
      <p>
        Mantenemos la información personal recopilada a través de nuestra aplicación
        durante el tiempo necesario para cumplir con los fines para los que se recopiló, a
        menos que se requiera o permita una retención más prolongada por ley. Una vez que
        la información personal ya no sea necesaria para los fines para los que se
        recopilacion ó, la eliminaremos de nuestros sistemas de manera segura.
      </p>

      <br />
      <h2>Derechos de los Usuarios</h2>

      <p>
        Los usuarios de nuestra aplicación tienen ciertos derechos en relación con su
        información personal. Estos derechos pueden incluir:
      </p>

      <p>
        <ol>
          <li>
            Acceso: Los usuarios tienen derecho a acceder a la información personal que
            hemos recopilado sobre ellos, y a solicitar una copia de dicha información.
          </li>

          <li>
            Rectificación: Los usuarios tienen derecho a corregir cualquier información
            personal inexacta o incompleta que tengamos sobre ellos.
          </li>

          <li>
            Eliminación: Los usuarios tienen derecho a solicitar la eliminación de su
            información personal de nuestros sistemas en determinadas circunstancias, como
            cuando la información ya no sea necesaria para los fines para los que se
            recopiló.
          </li>

          <li>
            Restricción del procesamiento: Los usuarios tienen derecho a solicitar la
            restricción del procesamiento de su información personal en ciertas
            circunstancias, como cuando la exactitud de la información sea impugnada o
            cuando el procesamiento sea ilegal.
          </li>

          <li>
            Portabilidad de datos: Los usuarios tienen derecho a solicitar la portabilidad
            de su información personal en un formato estructurado y legible por máquina.
          </li>

          <li>
            Oposición: Los usuarios tienen derecho a oponerse al procesamiento de su
            información personal en ciertas circunstancias, como cuando el procesamiento
            se basa en intereses legítimos.
          </li>

          <li>
            Retiro de consentimiento: Si el procesamiento de la información personal se
            basa en el consentimiento del usuario, los usuarios tienen derecho a retirar
            su consentimiento en cualquier momento.
          </li>
        </ol>
      </p>

      <p>
        Para ejercer cualquiera de estos derechos, los usuarios pueden ponerse en contacto
        con nosotros a través de los canales de comunicación proporcionados en nuestra
        aplicación.
      </p>

      <br />
      <h2>Cambios en la Política de Privacidad:</h2>

      <p>
        Nos reservamos el derecho de modificar o actualizar esta política de privacidad en
        cualquier momento y sin previo aviso. Cualquier cambio en esta política de
        privacidad será efectivo cuando se publique en nuestra aplicación. Recomendamos a
        los usuarios revisar periódicamente esta política de privacidad para mantenerse
        informados sobre nuestras prácticas de privacidad.
      </p>

      <br />
      <h2>Contacto</h2>
      <p>
        Si tiene preguntas, inquietudes o solicitudes relacionadas con nuestra política de
        privacidad o la manera en que manejamos la información personal, puede ponerse en
        contacto con nosotros a través de los canales de comunicación proporcionados en
        nuestra aplicación.
      </p>

      <p>
        Conclusión En <b>Portinos</b>, nos comprometemos a proteger la privacidad y la
        seguridad de la información personal de nuestros usuarios. Esta política de
        privacidad describe cómo recopilamos, utilizamos y protegemos la información
        personal en nuestra aplicación de gestión de recursos humanos. Al utilizar nuestra
        aplicación, usted acepta los términos y condiciones establecidos en esta política
        de privacidad.
      </p>
    </section>
  );
};

export default Privacy;
