/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
// Librerias
import React, { useEffect, useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
import Lottie from 'lottie-react';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks
import useModal from '../../hooks/useModal';
import useOffers, { useDeleteMultiplesOffer } from '../../hooks/useOffers';
import useProfile from '../../hooks/useProfile';

// Componentes
import JobCards from '../../components/Offers/JobCards';
import OffersModal from '../../components/Offers/OffersModal';
import Loader from '../../assets/animations/lottie-loading.json';
import LottieError from '../../assets/animations/lottie-error.json';
import OffersFooter from '../../components/Offers/OffersFooter';
import OffersCategoriesFilters from '../../components/Offers/OffersCategoriesFilters';
import ModalDelete from '../../components/Modals/ModalDelete';
import { useCategories } from '../../hooks/useCategories';

export interface CategoriesFilters {
  value: string | number;
  label: string;
}
interface Applicant {
  id: number;
  active: boolean;
  names: string;
  last_names: string;
  email: string;
}
interface Category {
  parent: number;
  name: string;
  id: number;
}

const Offers = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const path: string | null = location.pathname.split('/').filter(Boolean)[1];

  // Estados
  const [openEditModal, setOpenEditModal] = useState<boolean>(true);
  const [multiElements, setMultiElements] = useState<number[]>([]);
  const [currentItems, setCurrentItems] = useState<Applicant[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('all');
  const [categoriesFilter, setCategoriesFilter] = useState<CategoriesFilters[]>([
    { value: 'all', label: 'Mostrar todos' },
  ]);

  // Contextos
  const { theme } = useContext(ThemeContext);
  const userId = sessionStorage.getItem('userId');
  const session = useProfile(userId);

  // Hooks
  const [isShowingModal, toggleModal] = useModal();
  const { data, isLoading, isError, isSuccess, filters, setFilters, refetch } =
    useOffers();
  const { handleDelete, deleteMultiplesOffers } = useDeleteMultiplesOffer();
  const { data: categories } = useCategories();

  // Funciones
  const handleContextMenu = (e: { preventDefault: () => void }, id: number): void => {
    e.preventDefault();
    const index: number = multiElements.indexOf(id);

    // Establece los elementos seleccionados en el arreglo multiElements. Si el índice es -1, significa que no hay ningún elemento seleccionado y agrega el nuevo elemento al arreglo, de lo contrario filtra los elementos para eliminar el que ya estaba seleccionado.
    setMultiElements(
      index === -1 ? [...multiElements, id] : multiElements.filter((item) => item !== id)
    );
  };

  const handleModal = (isEdit: boolean): void => {
    toggleModal();
    if (isEdit) {
      setOpenEditModal(true);
    } else {
      setOpenEditModal(false);
    }
  };

  // UseEffects

  useEffect(() => {
    if (deleteMultiplesOffers.data) {
      toggleModal();
      deleteMultiplesOffers.reset();
    }
  }, [deleteMultiplesOffers]);

  useEffect(() => {
    const hasNewPost: string | null = sessionStorage.getItem('newPost');

    if (hasNewPost) {
      void refetch();
      sessionStorage.setItem('newPost', 'false');
    }
  }, [isShowingModal]);

  useEffect(() => {
    navigate(`/offers/${selectedTab}`);

    setFilters({
      ...filters,
      page: 1,
      active: selectedTab === 'active' ? true : selectedTab === 'hidden' ? false : null,
      expired: selectedTab === 'inactive' || null,
    });
  }, [selectedTab]);

  const memoizedCategoriesFilter = React.useMemo(() => {
    if (categories?.data && path !== undefined) {
      const newCategories = categories.data.reduce(
        (acc: CategoriesFilters[], category: Category) => {
          if (category.parent === 1) {
            const { name, id } = category;

            const newCategory = { value: id, label: name };

            if (
              !acc.some(
                (existingCategory) => existingCategory.value === newCategory.value
              )
            ) {
              acc.push(newCategory);
            }
          }
          return acc;
        },
        []
      );
      return [...categoriesFilter, ...newCategories];
    }
    return categoriesFilter;
  }, [categories?.data, path, categoriesFilter]);

  useEffect(() => {
    document.title = 'Ofertas - Recursos Humanos | Portinos';
  }, []);

  // Renders
  return (
    <>
      {openEditModal ? (
        <OffersModal
          theme={theme}
          handleClick={toggleModal}
          isShowingModal={isShowingModal}
        />
      ) : (
        <ModalDelete
          setCleanData={() => null}
          toggleModal={toggleModal}
          isShowingModal={isShowingModal}
          isLoading={deleteMultiplesOffers.isLoading}
          handleAction={async () => handleDelete(JSON.stringify({ ids: multiElements }))}
        />
      )}

      <section className={`offers ${theme}`}>
        <div className='offers__header'>
          <h2>Búsquedas</h2>

          <OffersCategoriesFilters
            filters={filters}
            setFilters={setFilters}
            categoriesOptions={memoizedCategoriesFilter}
          />
        </div>

        <div className='offers__tabs'>
          <ul>
            {/* active */}
            <li
              className={selectedTab === 'all' ? 'selected' : ''}
              onClick={() => {
                setSelectedTab('all');
              }}
            >
              <p>Todas </p>
              {selectedTab === 'all' ? (
                <motion.div className='underline' layoutId='underline' />
              ) : null}
            </li>

            {/* active */}
            <li
              className={selectedTab === 'active' ? 'selected' : ''}
              onClick={() => {
                setSelectedTab('active');
              }}
            >
              <p>Publicadas </p>
              {selectedTab === 'active' ? (
                <motion.div className='underline' layoutId='underline' />
              ) : null}
            </li>

            {/* hidden */}
            <li
              className={selectedTab === 'hidden' ? 'selected' : ''}
              onClick={() => {
                setSelectedTab('hidden');
              }}
            >
              <p>Ocultas </p>
              {selectedTab === 'hidden' ? (
                <motion.div className='underline' layoutId='underline' />
              ) : null}
            </li>

            {/* inactive */}
            <li
              className={selectedTab === 'inactive' ? 'selected' : ''}
              onClick={() => {
                setSelectedTab('inactive');
              }}
            >
              <p>Expiradas </p>
              {selectedTab === 'inactive' ? (
                <motion.div className='underline' layoutId='underline' />
              ) : null}
            </li>
          </ul>

          {multiElements.length > 0 && session.data.role !== 2 ? (
            <button
              onClick={() => {
                handleModal(false);
              }}
            >
              <span>{multiElements.length}</span>
              Eliminar
            </button>
          ) : null}
        </div>

        <AnimatePresence mode='wait'>
          {isLoading && (
            <div className='offers__loader'>
              <Lottie className='lottie' animationData={Loader} />
            </div>
          )}

          {isError && (
            <div className='offers__loader'>
              <Lottie className='lottie' animationData={LottieError} />
            </div>
          )}

          {isSuccess && (
            <>
              <motion.div
                key={selectedTab || 'empty'}
                className='offers__container'
                exit={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.2 }}
                initial={{ y: 10, opacity: 0 }}
              >
                <button
                  className='offers__create'
                  onClick={() => {
                    handleModal(true);
                  }}
                >
                  <BsPlusLg />
                  Crear nueva búsqueda
                </button>

                {currentItems?.length ? (
                  currentItems?.map((item: Applicant) => (
                    <JobCards
                      data={item}
                      key={item.id}
                      // title={item.title}
                      title={(item as Applicant & { title: string }).title}
                      selectedTab={selectedTab}
                      // applicants={item.applicants}
                      applicants={(item as Applicant & { applicants: number }).applicants}
                      // categories={item.categories[0]}
                      categories={
                        (item as Applicant & { categories: Record<string, string> })
                          .categories
                      }
                      onContextMenu={(e: { preventDefault: () => void }): void => {
                        handleContextMenu(e, item.id);
                      }}
                      multiElements={multiElements}
                    />
                  ))
                ) : (
                  <p className='offers__none'>
                    No hay búsquedas que coincidan con los filtros seleccionados
                  </p>
                )}
              </motion.div>

              <OffersFooter
                theme={theme}
                offers={data}
                filters={filters}
                setFilters={setFilters}
                currentItems={currentItems}
                setCurrentItems={setCurrentItems}
              />
            </>
          )}
        </AnimatePresence>
      </section>
    </>
  );
};

export default Offers;
