/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */

// Componentes
import { type Filters } from '../../types/ReactQuery';
import FiltersSelects from '../Forms/FiltersSelects';

interface Props {
  // filters: Record<string, number | string>;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  categoriesOptions: Array<{ value: string; label: string }>;
}

interface FilterOption {
  value: string;
  label: string;
}

const OffersCategoriesFilters = ({
  filters,
  setFilters,
  categoriesOptions,
}: Props): JSX.Element => {
  // Funciones
  const handleFilters = (key: FilterOption): void => {
    // key !== 0 &&
    setFilters({
      ...filters,
      categories: key.value !== 'all' ? key.value : '',
    });
  };

  return (
    <>
      <div className='offers__filters'>
        {/* <p>
          Categorías padre:
          <FiltersSelects
            name='parents'
            options={parentOptions}
            onChange={handleFilters}
            defaultValue={parentOptions[0]}
          />
        </p> */}

        <article>
          <span>Categorías:</span>
          <FiltersSelects
            large='mw-115'
            name='categories'
            onChange={handleFilters}
            options={categoriesOptions}
            loading={categoriesOptions.length <= 1}
            defaultValue={
              categoriesOptions.find(
                (item: FilterOption) => item.value === filters.categories
              ) || categoriesOptions[0]
            }
          />
        </article>

        {/* <p>
          Subcategorías:
          <FiltersSelects
            name=''
            options={subCategoriesOptions}
            defaultValue={subCategoriesOptions[0]}
          />
        </p> */}
      </div>
    </>
  );
};

export default OffersCategoriesFilters;
