/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

// Contextos

// Hooks
import { useCreateSubCategorie } from '../../../hooks/useSubCategories';

// Componentes
import SubCategoriesForm from './SubCategoriesForm';
import Modal from '../../Modal';
import { type ThemeContextType } from '../../../types/ThemeContext';

// Imagenes

// Estilos

// Types
interface Props {
  handleClick: () => void;
  isShowingModal: boolean;
  secondColumn: number | null;
  theme: ThemeContextType['theme'];
}

/**
 * Descripción: Modal que contiene el formulario para crear/actualizar una categoria.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const SubCategoriesModal = ({
  theme,
  handleClick,
  secondColumn,
  isShowingModal,
}: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { newSubCategorie, createSubCategories } = useCreateSubCategorie();

  const {
    register,
    handleSubmit,
    control,
    reset,
    unregister,
    formState: { errors },
  } = useForm();

  // Funciones

  // UseEffects
  useEffect(() => {
    if (newSubCategorie.isLoading) {
      toast.loading('Enviando...');
    }

    if (newSubCategorie.data) {
      newSubCategorie.reset();
      reset();
      handleClick();
    }
  }, [newSubCategorie]);

  // Renders
  return (
    <>
      <Modal show={isShowingModal} theme={theme}>
        <SubCategoriesForm
          theme={theme}
          errors={errors}
          control={control}
          register={register}
          unregister={unregister}
          handleClick={handleClick}
          secondColumn={secondColumn}
          handleSubmit={handleSubmit}
          createSubCategories={createSubCategories}
        />
      </Modal>{' '}
    </>
  );
};

export default SubCategoriesModal;
