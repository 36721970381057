/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */

// Librerias
import React, { useEffect, useContext, type Dispatch, type SetStateAction } from 'react';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks

// Componentes
import ImgGenerator from '../../helpers/ImgGenerator';
import { formatInputDate } from '../../helpers/Dates';

// Imagenes

// Estilos

interface Props {
  applicants: Applicant[] | null;
  applicantSelected: Applicant | null;
  setApplicantSelected: Dispatch<SetStateAction<Applicant | null>>;
}
export interface Applicant {
  id: number;
  names: string;
  last_names: string;
  updatedAt: string;
}

/**
 * Descripción: Lista que renderiza los aplicantes
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const OffersAplicants = ({
  applicants,
  applicantSelected,
  setApplicantSelected,
}: Props): JSX.Element => {
  // Estados

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks

  // Funciones

  // UseEffects
  useEffect(() => {
    if (applicants?.length) {
      setApplicantSelected(applicants[0]);
    }
  }, []);

  // Renders
  return (
    <>
      <div className={`ofapplicants ${theme}`}>
        <div className={`ofapplicants__list ${!applicants?.length && 'empty'}`}>
          {applicants?.length ? (
            applicants.map((item: Applicant, index) => (
              <div
                key={index}
                className={`item ${applicantSelected?.id === item.id && 'item__active'}`}
                onClick={() => {
                  setApplicantSelected(item);
                }}
              >
                <img src={ImgGenerator(`${item.names} ${item.last_names}`)} alt='' />
                <div className='item__text'>
                  <h3>{`${item.names} ${item.last_names}`}</h3>
                  {/* <p> {item.offer ? item.offer.title : 'Sin busqueda'}</p> */}
                </div>
                <p className='date'>{formatInputDate(item.updatedAt)} </p>
              </div>
            ))
          ) : (
            <p>No hay postulantes que coincidan con esta oferta</p>
          )}
        </div>
      </div>
    </>
  );
};

export default OffersAplicants;
