/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import api from '../services/api.services';
import { KEY_APPLICANTS } from '../constants/applicants.constant';

// Otros
import {
  type TokenType,
  type BodyType,
  type IdType,
  type UseQueryResponse,
} from '../types/ReactQuery';

import {
  type ApplicantsInterface,
  type UpdateApplicantInterface,
} from './useApplicants.types';

// fetch single applicant
export const useApplicant = (
  id: IdType | undefined
): Omit<UseQueryResponse, 'filters'> => {
  const token: TokenType = sessionStorage.getItem('token');

  const { data, isLoading, isError, error, refetch, isSuccess } = useQuery(
    [KEY_APPLICANTS, id],
    async () => api.applicants.getApplicant(token, id)
  );

  return { data, isError, isLoading, error, refetch, isSuccess };
};

// update applicant
export const useUpdateApplicant = (): UpdateApplicantInterface => {
  const token: TokenType = sessionStorage.getItem('token');
  const queryClient = useQueryClient();

  const updatedApplicant = useMutation({
    mutationFn: async ({ id, body }: { id: IdType; body: BodyType }) => {
      return api.applicants.updateApplicant(token, id, body);
    },

    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_APPLICANTS]);

      if (data.error) {
        toast.dismiss();
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message);
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    },
  });

  const updateApplicant = async (id: IdType, body: BodyType): Promise<void> => {
    updatedApplicant.mutate({ id, body });
  };

  return { updatedApplicant, updateApplicant };
};

// fetch all applicants
const useApplicants = (): Omit<ApplicantsInterface, 'refetch'> => {
  const token: TokenType = sessionStorage.getItem('token');

  // pasar logica de page
  // useEffect de page y filters

  // setting default filters
  const [filters, setFilters] = useState({
    size: 20,
    sort: 'desc',
    order: 'created-at',
  });

  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    // si cambio filtros reseteo la pagina
    setPage(0);
  }, [filters]);

  const searchParams = { ...filters, page: page + 1 };

  const { data, isLoading, isError, error, isSuccess } = useQuery(
    [KEY_APPLICANTS, searchParams],
    async () => api.applicants.getAllApplicants(token, searchParams)
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filters,
    setFilters,
    setPage,
    page,
    isSuccess,
  };
};

export default useApplicants;
