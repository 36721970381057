import { useContext, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import Img from 'react-cool-img';
import toast from 'react-hot-toast';
import useProfile, { useUpdateProfile } from '../../hooks/useProfile';
import ProfileEdit from '../../assets/images/icons/profile_edit.png';
import LoaderImage from '../../assets/images/static/loader.gif';
import ErrorImage from '../../assets/images/icons/general__icon.png';
import { type ThemeContextType } from '../../types/ThemeContext';
import SidebarContext from '../../context/SidebarContext';

interface Props {
  theme: ThemeContextType['theme'];
}

interface FormInputs {
  avatar: FileList;
  firtsName: string;
  lastName: string;
}

const AccountBasicInfoForm = ({ theme }: Props): JSX.Element => {
  const [avatarPreview, setAvatarPreview] = useState<null | string>(null);
  const { updateProfile } = useUpdateProfile();
  const user = sessionStorage.getItem('userId');
  const { data, isLoading, isSuccess } = useProfile(user);
  const { setUserAvatarImg, setFirstName, setLastName, firstName, lastName } =
    useContext(SidebarContext);

  const {
    register,
    formState,
    reset,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      avatar: data?.avatar,
      firtsName: data?.firtsName,
      lastName: data?.lastName,
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = async (formData: FormInputs) => {
    const submitData = new FormData();

    // Iterate over dirtyFields to append only changed values to submitData
    Object.keys(dirtyFields).forEach((key) => {
      const formKey = key as keyof FormInputs;

      if (formKey === 'avatar') {
        // Check if the avatar has been changed
        if (
          dirtyFields.avatar !== null &&
          formData.avatar instanceof FileList &&
          formData.avatar.length > 0
        ) {
          console.log('Avatar has changed.');
          submitData.append('avatar', formData.avatar[0]);
          setUserAvatarImg(URL.createObjectURL(formData.avatar[0]));
        }
      } else {
        // Handle other fields that might have changed
        const value = formData[formKey];
        const firtsNameValue = formData.firtsName;
        const lastNameValue = formData.lastName;
        if (value !== undefined) {
          submitData.append(formKey, String(value));
          setFirstName(firtsNameValue === '' ? data.firtsName : firtsNameValue);
          setLastName(lastNameValue === '' ? data.lastName : lastNameValue);
        }
      }
    });

    // Proceed to submit only if there are dirty fields
    if (Object.keys(dirtyFields).length > 0) {
      try {
        await updateProfile(user, submitData as any);
      } catch (error) {
        toast.error('Ha ocurrido un error');
        console.error('Error:', error);
      } finally {
        toast.success('Perfil actualizado con éxito');
      }
    } else {
      console.log('No fields have changed.');
      toast.error('No se detectaron cambios en el formulario.');
    }
  };

  const handleReset = (): void => {
    if (!formState.isDirty) return;

    reset();
    setAvatarPreview(null);

    toast.success('El formulario ha sido restablecido');
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === 'avatar' && type === 'change') {
        const file = value.avatar?.[0];
        if (file != null) {
          setAvatarPreview(URL.createObjectURL(file));
        }
      }
    });
  }, [watch]);

  return (
    <>
      <form
        className={`accountform ${theme}`}
        encType='multipart/form-data'
        method='put'
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Información basica */}
        <div className='accountform__row'>
          <div className='accountform__column'>
            <h3>Información básica:</h3>
            <p>Escriba su información básica.</p>
          </div>

          <div className='accountform__form'>
            <label htmlFor='avatar'>
              Foto de perfil:
              <div className='accountform__profile'>
                {isLoading && (
                  <Img
                    className='accountform__profile__avatar'
                    src={LoaderImage}
                    alt='user photo profile'
                  />
                )}

                {isSuccess && (
                  <Img
                    className='accountform__profile__avatar'
                    placeholder={LoaderImage}
                    src={avatarPreview === null ? data?.avatar : avatarPreview}
                    error={ErrorImage}
                    alt='user photo profile'
                    cache={false}
                  />
                )}

                <img
                  className='accountform__profile__icon'
                  src={ProfileEdit}
                  alt='Editar perfil icon'
                />

                <input
                  type='file'
                  id='avatar'
                  accept='image/jpeg, image/png, image/jpg'
                  className={errors.avatar != null ? 'error' : ''}
                  {...register('avatar')}
                />
              </div>
            </label>

            <label htmlFor='firtsName'>
              Nombre:
              <input
                type='text'
                id='firtsName'
                placeholder='Actualice su nombre'
                autoComplete='new-password'
                autoCapitalize='true'
                autoSave='false'
                className={errors.firtsName != null ? 'error' : ''}
                {...register('firtsName')}
                defaultValue={firstName === undefined ? data?.firtsName : firstName}
              />
              {errors.firtsName !== undefined && (
                <span role='alert'>{errors.firtsName.message as string}</span>
              )}
            </label>

            <label htmlFor='lastName'>
              Apellido:
              <input
                type='text'
                id='lastName'
                placeholder='Actualice su apellido'
                autoComplete='new-password'
                autoCapitalize='true'
                autoSave='false'
                className={errors.lastName != null ? 'error' : ''}
                {...register('lastName')}
                defaultValue={lastName === undefined ? data?.lastName : lastName}
              />
              {errors.lastName != null && (
                <span role='alert'>{errors.lastName?.message as string}</span>
              )}
            </label>
          </div>
        </div>
        <div className='accountform__footer'>
          {formState.isDirty && (
            <button type='button' onClick={handleReset}>
              Limpiar
            </button>
          )}
          <button type='submit'>Actualizar perfil</button>
        </div>
      </form>
    </>
  );
};

export default AccountBasicInfoForm;
