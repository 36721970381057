/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import ReactDOM from 'react-dom';
import React, { type ReactPortal, type ReactNode } from 'react';

// Contextos

// Hooks

// Componentes

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../types/ThemeContext';

interface Props {
  show: boolean;
  theme: ThemeContextType['theme'];
  children: ReactNode;
  prompt?: boolean | null;
}

/**
 * Descripción: Modal que contiene el formulario para hacer una oferta/busqueda
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Modal = ({ show, children, theme, prompt = null }: Props): ReactPortal | null => {
  // Estados

  // Contextos

  // Hooks

  // Funciones
  if (!show) {
    return null;
  }

  // UseEffects

  // Renders
  return ReactDOM.createPortal(
    <div className={`modal ${prompt ? 'prompt' : ''} ${theme}`}>{children}</div>,
    document.body
  );
};

export default Modal;
