import IconDesign from '../assets/images/icons/design_icon.png';
import IconDev from '../assets/images/icons/dev_icon.png';
import IconAccount from '../assets/images/icons/account_icon.png';
import IconDevOps from '../assets/images/icons/devops_icon.png';
import IconHr from '../assets/images/icons/hr_icon.png';
import IconCuentas from '../assets/images/icons/contador__icon.png';
import IconGeneral from '../assets/images/icons/general__icon.png';
import IconPM from '../assets/images/icons/pm__icon.png';

function iconByArea(area = ''): string {
  let icon = IconGeneral;

  const categories = [
    { name: 'Desarrollo', icon: IconDev },
    { name: 'DevOps', icon: IconDevOps },
    { name: 'Diseño', icon: IconDesign },
    { name: 'Ventas', icon: IconAccount },
    { name: 'RRHH', icon: IconHr },
    { name: 'Cuentas', icon: IconCuentas },
    { name: 'PM', icon: IconPM },
    { name: '', icon: IconGeneral }
  ];

  categories.forEach((item) => item.name === area && (icon = item.icon));

  return icon;
}

// iconByArea but using switch
// function iconByArea(area) {
//   switch (area) {
//     case 'Desarrollo':
//       return IconDev;
//     case 'DevOps':
//       return IconDevOps;
//     case 'Diseño':
//       return IconDesign;
//     case 'Ventas':
//       return IconAccount;
//     case 'RRHH':
//       return IconHr;
//     case 'Cuentas':
//       return IconCuentas;
//     case 'PM':
//       return IconPM;
//     default:
//       return IconGeneral;
//   }
// }

export default iconByArea;
