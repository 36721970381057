/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { createContext, useState, useEffect, type ReactNode } from 'react';

// Otros
import { type ThemeContextType } from '../types/ThemeContext';

/**
 * Descripcion del context: Cambia entre darkmode y ligth mode
 *
 * Implementacion: Descripción sobre cómo puedes implementar el context.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const initialThemeState = {
  theme: localStorage.getItem('globalTheme') || 'light',
  toggleTheme: () => null
};

const ThemeContext = createContext<ThemeContextType>(initialThemeState);

export const ThemeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [theme, setTheme] = useState<string>(initialThemeState.theme);

  const { localStorage } = window;
  const { body } = document;
  const html = document.querySelector('html') as HTMLElement;

  const toggleTheme = (theme: string): void => {
    localStorage.setItem('globalTheme', theme);

    if (theme === 'dark') {
      body.classList.add('dark');
      html.classList.add('dark');
    }

    if (theme === 'light') {
      body.classList.remove('dark');
      html.classList.remove('dark');
    }
    setTheme(theme);
  };

  const contextValue: ThemeContextType = {
    theme,
    toggleTheme
  };

  useEffect(() => {
    const savedThemeLocal: string | null = localStorage.getItem('globalTheme');

    if (savedThemeLocal !== null) {
      body.setAttribute('class', savedThemeLocal);
      body.classList.add(savedThemeLocal);
      html.setAttribute('class', savedThemeLocal);
      html.classList.add(savedThemeLocal);
      setTheme(savedThemeLocal);
    }
  }, []);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export default ThemeContext;
