/* eslint-disable react/forbid-prop-types */
// Librerias
import React, { useContext } from 'react';
import ReactSelect from 'react-select';
// import PropTypes from 'prop-types';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks

// Componentes

// Imagenes

// Estilos

// Types

interface Props {
  name: string;
  large: string;
  loading: boolean;
  menuPlacement: 'auto' | 'bottom' | 'top';
  loadingMessage: ((obj: { inputValue: string }) => React.ReactNode) | undefined;
  isSearchable: boolean;
  onChange: (value: string | any) => void;
  options: Array<Record<string, unknown>>;
  defaultValue?: Record<string, unknown>;
}

/**
 * Descripción: Este componente crea una instancia de un selector, se usa para el filtrado ya que esta pensado es para reaccionar en tiempo real
 *
 * Implementación: Se importa y se importan los props de la parte inferior
 *
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const FiltersSelects = ({
  name,
  large,
  options,
  loading,
  onChange,
  defaultValue,
  isSearchable,
  menuPlacement,
  loadingMessage,
}: Props): JSX.Element => {
  // Estados

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <>
      <ReactSelect
        name={name}
        options={options}
        isLoading={loading}
        loadingMessage={loadingMessage}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        menuPlacement={menuPlacement}
        classNamePrefix='filter__select'
        className={`filter__select ${theme} ${large}`}
        // isLoading={graphicsPerCountry.loading}
        onChange={(value: string | unknown) => {
          onChange(value);
        }}
      />
    </>
  );
};

// // Definimos los tipos de propiedades que acepta el componente FiltersSelects
// FiltersSelects.propTypes = {
//   // Nombre del componente, debe ser un string
//   name: PropTypes.string,
//   // Opciones del componente, debe ser un array
//   options: PropTypes.array,
//   // Indica si el componente es buscable o no, debe ser un booleano
//   isSearchable: PropTypes.bool,
//   // Ubicación del menú desplegable, debe ser un string
//   menuPlacement: PropTypes.string,
//   // Valor por defecto del componente, debe ser un objeto
//   defaultValue: PropTypes.object,
//   loading: PropTypes.bool,
//   loadingMessage: PropTypes.string,
//   large: PropTypes.string
// };

// Definimos los valores por defecto para las propiedades del componente FiltersSelects en caso de no recibir ninguno desde el exterior.
FiltersSelects.defaultProps = {
  name: '', // Nombre vacío por defecto
  options: [], // Array vacío por defecto
  isSearchable: false, // Por defecto el componente no es buscable
  menuPlacement: 'bottom', // El menú desplegable se ubica abajo por defecto
  defaultValue: { value: '', defaultValue: 'no content' }, // Valor por defecto vacío con mensaje "no content"
  loading: false,
  loadingMessage: 'Cargando...',
  large: null,
};

export default FiltersSelects;
