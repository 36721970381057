/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

// Contextos

// Hooks
import { useCategorie, useUpdateCategorie } from '../../../hooks/useCategories';

// Componentes
import UpdateCategoriesForm from './UpdateCategoriesForm';
import Modal from '../../Modal';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../../types/ThemeContext';
import { type IdType } from '../../../types/ReactQuery';

interface Props {
  OptionID: IdType;
  isShowingModal: boolean;
  handleClick: () => void;
  theme: ThemeContextType['theme'];
}

/**
 * Descripción: Modal que contiene el formulario para crear/actualizar una categoria.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const UpdateCategoriesModal = ({
  theme,
  OptionID,
  handleClick,
  isShowingModal,
}: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { data } = useCategorie(OptionID);
  const { updatedCategorie, updateCategorie } = useUpdateCategorie();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  // Funciones

  // UseEffects
  useEffect(() => {
    if (updatedCategorie.isLoading) {
      toast.loading('Enviando...');
    }

    if (updatedCategorie.data) {
      updatedCategorie.reset();
      reset();
      handleClick();
    }
  }, [updatedCategorie]);

  // Renders
  return (
    <>
      {data ? (
        <Modal show={isShowingModal} theme={theme}>
          <UpdateCategoriesForm
            theme={theme}
            errors={errors}
            control={control}
            register={register}
            actualData={data}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
            updateCategorie={updateCategorie}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default UpdateCategoriesModal;
