// Librerias
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

// Contextos
import AplicantsList from '../../components/Applicants/ApplicantList';
import ThemeContext from '../../context/ThemeContext';

// Hooks

// Componentes

// Imagenes

// Estilos

/**
 * Descripción: Página donde se renderizan los aplicantes y se muestra la informacion de los mismos
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Applicants = (): JSX.Element => {
  // Estados
  const [applicantId, setApplicantId] = useState<string | number | null>(null);

  // Contextos
  const { theme } = React.useContext(ThemeContext);

  // Hooks

  // Funciones

  // UseEffects

  return (
    <div className={`aplicants ${theme}`}>
      <h2>Aplicantes</h2>
      <div className='aplicants__container'>
        <AplicantsList setApplicantId={setApplicantId} applicantId={applicantId} />
        <Outlet />
      </div>
    </div>
  );
};

export default Applicants;
