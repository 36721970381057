/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormHandleSubmit,
  type UseFormRegister,
  type UseFormUnregister,
} from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';

// Contextos

// Hooks
import { useParentCategories, useCategories } from '../../../hooks/useCategories';

// Componentes
import CreateSlug from '../../../helpers/CreateSlug';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../../types/ThemeContext';

interface Props {
  handleClick: () => void;
  createSubCategories: (data: string) => void;
  secondColumn: number | null;
  theme: ThemeContextType['theme'];
  errors: FieldValues;
  control: Control<FieldValues, Option>;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
}
interface Option {
  value?: number;
  label: string;
  id?: string | number;
  options?: Option[] | undefined | null;
}
interface SubCategoryData {
  parent: number;
  name: string;
  slug: string;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const SubCategoriesForm = ({
  theme,
  errors,
  control,
  register,
  unregister,
  handleClick,
  secondColumn,
  handleSubmit,
  createSubCategories,
}: Props): JSX.Element => {
  // Estados
  const [groupedOptions, setGroupedOptions] = useState<Option[]>([]);
  const [parentSelected, setParentSelected] = useState<any>(secondColumn);
  // secondColumn: number / parentSelected: [{label: string; value: number}]

  const [slugValue, setSlugValue] = useState<string>('');

  // Contextos

  // Hooks
  const parentCategories = useParentCategories();
  const categories = useCategories();

  // Funciones
  const nameRegistration = register('name', {
    required: `El titulo es requerido.`,
  });

  const slugRegistration = register('slug');

  // const formatGroupLabel = (data = groupedOptions): JSX.Element => (
  const formatGroupLabel = (data: { label: string; options: Option[] }): JSX.Element => (
    <div className={`groupselect ${theme}`}>
      <span className='groupselect__label'>{data.label}</span>
      <span className='groupselect__badge'>{data.options.length}</span>
    </div>
  );

  const onSubmit = (data: FieldValues): void => {
    const newData: SubCategoryData = {
      parent: parentSelected?.value || secondColumn,
      name: data.name,
      slug: slugValue,
    };
    createSubCategories(JSON.stringify(newData));
    sessionStorage.setItem('updateCategories', 'true');
    setSlugValue('');
  };

  const handleChange = (e: { target: { value: string } }): void => {
    setSlugValue(CreateSlug(e.target.value));
  };

  // UseEffects
  useEffect(() => {
    if (categories.isSuccess) {
      groupedOptions.forEach((parent: Option) => {
        categories.data?.data
          .filter(
            (categorie: { parent: number | string }) => categorie.parent === parent.id
          )
          .forEach(
            (categorie: { id: number; name: string }) =>
              parent.options?.push({ value: categorie.id, label: categorie.name })
          );
      });
    }

    if (secondColumn) {
      const selectedValues = categories.data?.data
        .filter((categorie: { id: number }) => categorie.id === secondColumn)
        .map((item: { id: number; name: string }) => ({
          value: item.id,
          label: item.name,
        }));
      setParentSelected(selectedValues);
    }
  }, [groupedOptions, categories.isSuccess]);

  useEffect(() => {
    if (parentCategories.isSuccess) {
      const newGroupedOptions: Option[] = [];

      parentCategories.data?.data.forEach((itemA: { id: number; name: string }) => {
        if (
          !newGroupedOptions.find(
            (itemB: { label: string }) => itemB.label === itemA.name
          )
        ) {
          newGroupedOptions.push({ id: itemA.id, label: itemA.name, options: [] });
          setGroupedOptions(newGroupedOptions);
        }
      });
    }
  }, [parentCategories.isSuccess]);

  useEffect(() => {
    return () => {
      setSlugValue('');
      unregister('parent');
      unregister('name');
      unregister('slug');
      setSlugValue('');
      setParentSelected(secondColumn);
    };
  }, []);

  // Renders
  return (
    <form className={`categoriesmodal ${theme}`} onSubmit={handleSubmit(onSubmit)}>
      <div className='categoriesmodal__header'>
        <h2>Crear Subcategoría:</h2>
        <button onClick={handleClick}>
          <AiOutlineCloseCircle />
        </button>
      </div>

      {/* Información basica */}
      <div className='categoriesmodal__row'>
        <div className='categoriesmodal__column'>
          <h3>Información de la subcategoría:</h3>
          <p>
            Escriba la información básica sobre la subcategoría, esto se usa para
            categorizar las ofertas y demás.
          </p>
        </div>

        <div className='categoriesmodal__form'>
          <div className='categoriesmodal__form__row'>
            <div className='categoriesmodal__form__row-inputContainer'>
              <p>Categoría:</p>
              <Controller
                name='parent'
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    isSearchable={false}
                    options={groupedOptions}
                    classNamePrefix='categoriesmodal__select'
                    className={`categoriesmodal__select ${theme}`}
                    value={parentSelected}
                    defaultValue={parentSelected}
                    onChange={(value) => {
                      setParentSelected(value);
                    }}
                    formatGroupLabel={formatGroupLabel}
                  />
                )}
              />
            </div>
          </div>

          <div className='categoriesmodal__form__row-inputContainer'>
            <p>Nombre:</p>
            <input
              className={errors.name != null ? 'error' : ''}
              id='name'
              type='text'
              {...nameRegistration}
              placeholder='Ej: Asistente de Mantenimiento'
              onChange={handleChange}
            />
            {errors.name != null && <span role='alert'>{errors.name?.message}</span>}
          </div>

          <div className='categoriesmodal__form__row-inputContainer'>
            <p>Slug:</p>
            <input
              readOnly
              className={errors.slug != null ? 'error' : ''}
              type='text'
              id='slug'
              placeholder='Se rellena automaticamente'
              value={slugValue}
              {...slugRegistration}
            />
            {errors.slug != null && <span role='alert'>{errors.slug?.message}</span>}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className='categoriesmodal__footer'>
        <button onClick={handleClick}>Cancelar</button>
        <button type='submit'>Publicar</button>
      </div>
    </form>
  );
};

export default SubCategoriesForm;
