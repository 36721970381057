const KEY_PARENT_CATEGORIES = 'parent-categories'
const GET_PARENT_CATEGORIES = process.env.REACT_APP_PARENT_CATEGORIES ?? ''
const CREATE_PARENT_CATEGORIES = process.env.REACT_APP_PARENT_CATEGORIES ?? ''
const DELETE_PARENT_CATEGORIES = process.env.REACT_APP_PARENT_CATEGORIES ?? ''
const UPDATE_PARENT_CATEGORIES = process.env.REACT_APP_PARENT_CATEGORIES ?? ''

const KEY_CATEGORIES = 'categories'
const GET_CATEGORIES = process.env.REACT_APP_CATEGORIES ?? ''
const CREATE_CATEGORIES = process.env.REACT_APP_CATEGORIES ?? ''
const DELETE_CATEGORIES = process.env.REACT_APP_CATEGORIES ?? ''
const UPDATE_CATEGORIES = process.env.REACT_APP_CATEGORIES ?? ''

const KEY_SUB_CATEGORIES = 'subcategories'
const GET_SUB_CATEGORIES = process.env.REACT_APP_SUB_CATEGORIES ?? ''
const CREATE_SUB_CATEGORIES = process.env.REACT_APP_SUB_CATEGORIES ?? ''
const DELETE_SUB_CATEGORIES = process.env.REACT_APP_SUB_CATEGORIES ?? ''
const UPDATE_SUB_CATEGORIES = process.env.REACT_APP_SUB_CATEGORIES ?? ''

export {
  KEY_PARENT_CATEGORIES,
  KEY_CATEGORIES,
  KEY_SUB_CATEGORIES,
  GET_PARENT_CATEGORIES,
  CREATE_PARENT_CATEGORIES,
  DELETE_PARENT_CATEGORIES,
  UPDATE_PARENT_CATEGORIES,
  GET_CATEGORIES,
  CREATE_CATEGORIES,
  DELETE_CATEGORIES,
  UPDATE_CATEGORIES,
  GET_SUB_CATEGORIES,
  CREATE_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORIES,
  DELETE_SUB_CATEGORIES
}
