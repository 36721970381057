/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Img from 'react-cool-img';
import { GoAlert } from 'react-icons/go';

// Contextos
import { Link } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';

// Hooks

// Componentes
import formatDate from '../../helpers/FormatDate';
import iconByArea from '../../helpers/IconByArea';
import LoaderImage from '../../assets/images/static/loader.gif';
import ErrorImage from '../../assets/images/icons/general__icon.png';

// Imagenes

// Estilos

// Types
import { type UseQueryResponse } from '../../types/ReactQuery';

interface Props {
  title: string;
  multiElements: number[];
  categories: Record<string, string>;
  applicants: number;
  selectedTab: string;
  onContextMenu: (e: { preventDefault: () => void }) => void;
}

type PartialProps = Pick<UseQueryResponse, 'data'> & Props;

/**
 * Descripción: Estas cards son las que muestran las ofertas.
 *
 * Implementación: Se importa y se pasan los props necesarios para rellenar la info
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const JobCards = ({
  data,
  title,
  multiElements,
  categories,
  applicants,
  selectedTab,
  onContextMenu,
}: PartialProps): JSX.Element => {
  // Estados
  const [selected, setSelected] = useState<boolean>(false);

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks

  // Funciones
  const today = new Date().toISOString();

  // UseEffects
  useEffect(() => {
    if (multiElements.find((item) => item === data.id)) setSelected(true);
    else setSelected(false);
  }, [multiElements]);

  // Renders
  return (
    <>
      <motion.div
        whileTap={{ scale: 0.4 }}
        className={`jobcard ${theme} ${selected ? 'selected' : ''}`}
        onContextMenu={onContextMenu}
      >
        <Link to={`/offers/${selectedTab}/details/${data.id}`}>
          <div className='jobcard__header'>
            <Img
              placeholder={LoaderImage}
              src={iconByArea(categories?.name)}
              error={ErrorImage}
              alt='Job icon'
            />
            <span>{formatDate(data.createdAt || data.updatedAt)}</span>
          </div>

          <div className='jobcard__title'>
            <span>{categories?.name}</span>
            <h3>{title}</h3>
            <small>Buenos Aires, Argentina</small>
          </div>

          <p className='jobcard__body'>{`${data.short_description.slice(0, 150)}.`}</p>

          <div className='jobcard__footer'>
            {data.end_date < today ? (
              <p>
                <GoAlert title='Esta publicación está a punto o ya expiro.' />
              </p>
            ) : (
              <div />
            )}
            <span>{applicants} aplicantes</span>
          </div>
        </Link>
      </motion.div>
    </>
  );
};
export default JobCards;
