/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import React from 'react';

// Contextos

// Hooks

// Componentes

// Imagenes
import MicrosoftLogo from '../../assets/images/icons/microsoft_icon.png';

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

interface Props {
  handleLoginPopup: () => void;
}

const LoginForm = ({ handleLoginPopup }: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <>
      <div className='login__form'>
        <h2>Inicia Sesión</h2>

        <button
          onClick={(e) => {
            e.preventDefault();
            handleLoginPopup();
          }}
        >
          <img src={MicrosoftLogo} alt='Microsoft logo' /> Inicia sesión con microsoft
        </button>
      </div>
    </>
  );
};

export default LoginForm;
