/* eslint-disable @typescript-eslint/strict-boolean-expressions */
function formatDate(date: string, complete: boolean = false): string {
  const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };

  if (date) {
    return new Date(Date.parse(date)).toLocaleDateString('es-AR', complete ? {} : options);
  }
  return 'No hay fecha';
}

export default formatDate;
