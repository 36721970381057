// Librerias
import React from 'react';
import Lottie from 'lottie-react';

// Contextos

// Hooks

// Componentes

// Imagenes
import InternalErrorAnim from '../../assets/animations/internal-error.json';

// Estilos

/**
 * Descripción: Sirve para mostrar una animación de "Internal Error"
 *
 * Implementación: Se lo agrega a un contenedor, el contenedor debe establecer el tamaño de la animaición.
 *
 * Bugs: 0.
 */

const InternalError = (): JSX.Element => {
  // Estados

  // Contextos

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <div className='internalerror'>
      <Lottie animationData={InternalErrorAnim} />
    </div>
  );
};

export default InternalError;
