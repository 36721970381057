/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import ReactSelect, { type SingleValue } from 'react-select';
import {
  Controller,
  type Control,
  type UseFormHandleSubmit,
  type UseFormRegister,
  type FieldErrors,
} from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';

// Contextos

// Hooks
import { useParentCategories } from '../../../hooks/useCategories';

// Componentes
import CreateSlug from '../../../helpers/CreateSlug';

// Imagenes

// Estilos

// Types
import { type IdType } from '../../../types/ReactQuery';
import { type ThemeContextType } from '../../../types/ThemeContext';

interface Props {
  actualData: { data: ActualData };
  handleClick: () => void;
  theme: ThemeContextType['theme'];
  handleSubmit: UseFormHandleSubmit<FormData>;
  errors: FieldErrors<FormData>;
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
  updateCategorie: (id: IdType, body: string | Record<string, unknown>) => Promise<void>;
}
interface ActualData {
  id: string;
  name: string;
  parent?: string;
  slug: string;
}
export interface FormData {
  updateName?: string;
  updateSlug?: string;
  updateParent?: string;
}
interface CategoryData {
  parent: string | undefined | number;
  name: string;
  slug: string;
}
interface Option {
  value: string | number | undefined;
}
/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const UpdateCategoriesForm = ({
  theme,
  errors,
  control,
  register,
  actualData,
  handleClick,
  handleSubmit,
  updateCategorie,
}: Props): JSX.Element => {
  // Estados
  const [parentOptions, setParentOptions] = useState([
    {
      value: '',
      label: 'Selecciona una categoria padre',
    },
  ]);

  const [parentSelected, setParentSelected] = useState<string | undefined | number>(
    actualData.data.parent
  );
  const [slugValue, setSlugValue] = useState<string>('');

  // Contextos

  // Hooks
  const parentCategories = useParentCategories();

  // Funciones
  const updateName = register('updateName', {});

  const updateSlug = register('updateSlug', {});

  const onSubmit = (data: FormData): void => {
    const newData: CategoryData = {
      parent: parentSelected || actualData.data.parent,
      name: data.updateName || actualData.data.name,
      slug: slugValue || actualData.data.slug,
    };
    void updateCategorie(actualData.data.id, JSON.stringify(newData));
    sessionStorage.setItem('updateCategories', 'true');
  };

  const handleChange = (e: { target: { value: string } }): void => {
    setSlugValue(CreateSlug(e.target.value));
  };

  // UseEffects
  useEffect(() => {
    if (parentCategories.isSuccess) {
      const newParents:
        | ((prevState: Array<{ value: string; label: string }>) => Array<{
            value: string /* eslint-disable no-unused-expressions */;
            label: string;
          }>)
        | Array<{ value: string; label: string }> = [];

      parentCategories.data?.data.forEach((itemA: { id: string; name: string }) => {
        if (!newParents.find((itemB) => itemB.value === itemA.id)) {
          newParents.push({ value: itemA.id, label: itemA.name });
          setParentOptions(newParents);
        }
      });
    }
  }, [parentCategories.isSuccess]);

  useEffect(() => {
    return () => {
      setParentSelected(actualData.data.parent);
      setSlugValue('');
    };
  }, []);

  // Renders
  return (
    <form className={`categoriesmodal ${theme}`} onSubmit={handleSubmit(onSubmit)}>
      <div className='categoriesmodal__header'>
        <h2>Actualizar Categoría:</h2>
        <button onClick={handleClick}>
          <AiOutlineCloseCircle />
        </button>
      </div>

      {/* Información basica */}
      <div className='categoriesmodal__row'>
        <div className='categoriesmodal__column'>
          <h3>Información de la categoría:</h3>
          <p>
            Escriba la información básica sobre la subcategoría, esto se usa para
            categorizar las ofertas y demás.
          </p>
        </div>

        <div className='categoriesmodal__form'>
          <div className='categoriesmodal__form__row'>
            <div className='categoriesmodal__form__row-inputContainer'>
              Categoría padre:
              <Controller
                name='updateParent'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    isSearchable={false}
                    options={parentOptions}
                    loadingMessage={() => 'Loading...'}
                    classNamePrefix='categoriesmodal__select'
                    className={`categoriesmodal__select ${theme}`}
                    onChange={(val: SingleValue<Option>) => {
                      onChange(val?.value);
                      setParentSelected(val?.value);
                    }}
                    value={
                      // parentOptions.find((s) => s.value === value) != null ||
                      // parentOptions.find((s) => s.value === actualData.data.parent)
                      (() => {
                        const selectedOption = parentOptions.find(
                          (s) => s.value === value
                        );
                        const selectedParentOption = parentOptions.find(
                          (s) => s.value === actualData.data.parent
                        );
                        return selectedOption || selectedParentOption;
                      })()
                    }
                  />
                )}
              />
            </div>
          </div>

          <div className='categoriesmodal__form__row-inputContainer'>
            <p>Nombre:</p>
            <input
              {...updateName}
              type='text'
              name='updateName'
              onChange={handleChange}
              placeholder={actualData.data.name}
              className={errors.updateName != null ? 'error' : ''}
            />
            {errors.updateName != null && (
              <span role='alert'>{errors.updateName?.message}</span>
            )}
          </div>

          <div className='categoriesmodal__form__row-inputContainer'>
            <p>Slug:</p>
            <input
              {...updateSlug}
              readOnly
              type='text'
              name='updateSlug'
              value={slugValue}
              defaultValue={actualData.data.slug}
              placeholder={actualData.data.slug}
              className={errors.updateSlug != null ? 'error' : ''}
              // Ta roto
            />
            {errors.updateSlug != null && (
              <span role='alert'>{errors.updateSlug?.message}</span>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className='categoriesmodal__footer'>
        <button onClick={handleClick}>Cancelar</button>
        <button type='submit' title='Post'>
          Publicar
        </button>
      </div>
    </form>
  );
};

export default UpdateCategoriesForm;
