/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */

// Librerias
import React, { useEffect, useRef, type MutableRefObject } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ReactSelect, { type SingleValue } from 'react-select';

// Contextos

// Hooks
import { toast } from 'react-hot-toast';
import { useCreateUser } from '../../hooks/useUser';

// Componentes
import Modal from '../Modal';
import { type ThemeContextType } from '../../types/ThemeContext';
import { type BodyType } from '../../types/ReactQuery';

// Imagenes

// Estilos

// Types
interface Props {
  theme: ThemeContextType['theme'];
  handleClick: () => void;
  isShowingModal: boolean;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const CreateUserForm = ({ theme, handleClick, isShowingModal }: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { createdResponse, sendNewUSer } = useCreateUser();

  const selectRef: MutableRefObject<null> = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    unregister,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firtsName: '',
      lastName: '',
      email: '',
      job: '',
      role: null,
    },
  });

  // Funciones
  const nameRegistration = register('firtsName', {
    required: `El nombre es requerido.`,
  });

  const lastNameRegistration = register('lastName', {
    required: `El apellido es requerido.`,
  });

  const emailRegistration = register('email', {
    required: `El correo es requerido.`,
    pattern: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@portinos\.com.ar$/,
      message: `El proveedor debe ser igual al de la empresa.`,
    },
  });

  const jobRegistration = register('job', {
    required: `El puesto es requerido.`,
  });

  const rolesOptions = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Recursos Humanos' },
  ];

  const validateRole = (value: number): boolean => {
    const selectedOption: { value: number; label: string } | undefined =
      rolesOptions.find(
        (option: { value: number; label: string } | undefined) => option?.value === value
      );
    return !(selectedOption === null);
  };

  const onSubmit = (data: BodyType): void => {
    void sendNewUSer(JSON.stringify(data));
  };

  // UseEffects
  useEffect(() => {
    if (createdResponse.isLoading) {
      toast.dismiss();
      toast.loading('Enviando');
    }

    if (createdResponse.data) {
      reset();
      createdResponse.reset();
    }
  }, [createdResponse]);

  useEffect(() => {
    return () => {
      reset();
      createdResponse.reset();
      unregister('firtsName');
      unregister('lastName');
      unregister('email');
      unregister('job');
      unregister('role');
    };
  }, [isShowingModal]);

  // Renders
  return (
    <>
      <Modal show={isShowingModal} theme={theme}>
        <form className={`userform ${theme}`} onSubmit={handleSubmit(onSubmit)}>
          <div className='userform__header'>
            <h2>Crear usuario:</h2>
            <button onClick={handleClick}>
              <AiOutlineCloseCircle />
            </button>
          </div>

          {/* Información basica */}
          <div className='userform__row'>
            <div className='userform__column'>
              <h3>Información básica:</h3>
              <p>
                Provee la información básica del usuario, una vez se haya completado y
                enviado la información le llegará vía mail.
              </p>
            </div>

            <div className='userform__form'>
              <label htmlFor='firtsName'>
                Nombre: <span className='required'>*</span>
                <input
                  type='text'
                  id='firtsName'
                  placeholder='Escriba un nombre'
                  autoComplete='new-password'
                  autoCapitalize='true'
                  autoSave='false'
                  className={errors.firtsName != null ? 'error' : ''}
                  {...nameRegistration}
                />
                {errors.firtsName != null && (
                  <span role='alert'>{errors.firtsName?.message}</span>
                )}
              </label>

              <label htmlFor='lastName'>
                Apellido: <span className='required'>*</span>
                <input
                  type='text'
                  id='lastName'
                  placeholder='Escriba un apellido'
                  autoComplete='new-password'
                  autoCapitalize='true'
                  autoSave='false'
                  className={errors.lastName != null ? 'error' : ''}
                  {...lastNameRegistration}
                />
                {errors.lastName != null && (
                  <span role='alert'>{errors.lastName?.message}</span>
                )}
              </label>

              <label htmlFor='role'>
                Rol: <span className='required'>*</span>
                <Controller
                  name='role'
                  control={control}
                  // rules={{ validate: validateRole }}
                  render={({ field: { onChange } }) => (
                    <ReactSelect
                      // ref={(ref) => (selectRef.current = ref)}
                      isSearchable={false}
                      options={rolesOptions}
                      loadingMessage={() => 'Loading...'}
                      defaultValue={{ value: '', label: 'Seleccione un rol' }}
                      classNamePrefix='offersmodal__select'
                      className={`offersmodal__select ${theme} ${
                        errors.role != null ? 'error' : ''
                      }`}
                      onChange={(
                        selectedOption: SingleValue<{
                          label: string;
                          value: string | number;
                        }>
                      ) => {
                        onChange(selectedOption?.value);
                      }}
                    />
                  )}
                />
                {errors.role != null && <span role='alert'>{errors.role?.message}</span>}
              </label>

              <label htmlFor='job'>
                Puesto: <span className='required'>*</span>
                <input
                  type='text'
                  id='job'
                  placeholder='Ej: Reclutador'
                  autoComplete='new-password'
                  autoCapitalize='true'
                  autoSave='false'
                  className={errors.job != null ? 'error' : ''}
                  {...jobRegistration}
                />
                {errors.job != null && <span role='alert'>{errors.job?.message}</span>}
              </label>

              <label htmlFor='email'>
                Correo: <span className='required'>*</span>
                <input
                  type='email'
                  id='email'
                  placeholder='Escriba el correo Portinero'
                  autoComplete='new-password'
                  autoCapitalize='true'
                  autoSave='false'
                  className={
                    errors.email != null || createdResponse.isError ? 'error' : ''
                  }
                  {...emailRegistration}
                />
                {(errors.email != null || createdResponse.isError) && (
                  <span role='alert'>
                    {errors?.email?.message || createdResponse.error.message}
                  </span>
                )}
              </label>
            </div>
          </div>

          {/* Footer */}
          <div className='userform__footer'>
            <button onClick={handleClick}>Cancelar</button>
            <button type='submit'>Crear nuevo usuario</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateUserForm;
