/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-return-await */
/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import { type UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

// Otros
import api from '../services/api.services';
import { KEY_SUB_CATEGORIES } from '../constants/categories.constant';
import {
  type SubCategorieUpdateInterface,
  type SubCategorieInterface,
  type SubCategorieCreateInterface,
  type SubCategorieDeleteInterface
} from './useSubCategories.types';
import { type BodyType, type IdType } from '../types/ReactQuery';

/**
 * Descripcion del hook: Este hook resuelve las mutaciones y llamadas al servicio con react query
 *
 * Implementacion: Descripción sobre cómo puedes implementar el hook.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

export const useCreateSubCategorie = (): SubCategorieCreateInterface=> {
  const token = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const newSubCategorie = useMutation({
    mutationFn: async (body: BodyType) => await api.subCategories.create(token, body),
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_SUB_CATEGORIES]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Oferta creada correctamente! ❤️');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  const createSubCategories = async (body: BodyType): Promise<void> => {
    newSubCategorie.mutate(body);
  };

  return { newSubCategorie, createSubCategories };
};

export const useDeleteSubCategorie = (): SubCategorieDeleteInterface => {
  const token = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const deleteSubCategories = useMutation({
    mutationFn: async (id: IdType) => await api.subCategories.delete(token, id),
    onSuccess: (data) => {
      // Cuando la operación es exitosa, se realiza un refetch a las Categorias
      void queryClient.invalidateQueries([KEY_SUB_CATEGORIES]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Eliminado correctamente!');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  const handleDelete = async (id: IdType): Promise<void> => {
    deleteSubCategories.mutate(id);
  };

  return { handleDelete, deleteSubCategories };
};

export const useUpdateSubCategorie = (): SubCategorieUpdateInterface => {
  const token = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const updatedSubCategorie = useMutation({
    mutationFn: async ({ id, body }: { id: IdType; body: BodyType }) => {
      // Se realiza la petición al servidor para actualizar la oferta con los datos proporcionados
      return await api.subCategories.update(token, id, body);
    },

    // Si la petición es exitosa se invalida las consultas relacionadas con las Categorias y se muestra un mensaje de éxito o error dependiendo del resultado obtenido del servidor
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_SUB_CATEGORIES]);

      if (data.error) {
        toast.dismiss();
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message);
      }
    },

    // Si ocurre algún error en la petición se muestra un mensaje de error al usuario informando del problema ocurrido
    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  const updateSubCategorie = async (id: IdType, body: BodyType): Promise<void> => {
    updatedSubCategorie.mutate({ id, body });
  };

  return { updatedSubCategorie, updateSubCategorie };
};

export const useSubCategorie = (id: IdType): SubCategorieInterface => {
  const token = sessionStorage.getItem('token');
  const { data, isLoading, error, refetch } = useQuery(
    [KEY_SUB_CATEGORIES, id],
    async () => await api.subCategories.getOne(token, id)
  );

  return { data, isLoading, error, refetch };
};

export const useSubCategories = (): UseQueryResult<any, unknown> => {
  const token = sessionStorage.getItem('token');

  const subCategories = useQuery(
    [KEY_SUB_CATEGORIES],
    async () => await api.subCategories.getAll(token)
  );

  return subCategories;
};
