/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

// Contextos

// Hooks
import { useCreateCategorie } from '../../../hooks/useCategories';

// Componentes
import CategoriesForm from './CategoriesForm';
import Modal from '../../Modal';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../../types/ThemeContext';

interface Props {
  theme: ThemeContextType['theme'];
  firtsColumn: number;
  handleClick: () => void;
  isShowingModal: boolean;
}

/**
 * Descripción: Modal que contiene el formulario para crear/actualizar una categoria.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const CategoriesModal = ({
  theme,
  firtsColumn,
  handleClick,
  isShowingModal
}: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { newCategorie, createCategories } = useCreateCategorie();

  const {
    register,
    handleSubmit,
    control,
    reset,
    unregister,
    formState: { errors }
  } = useForm({
    defaultValues: {
      parent: firtsColumn,
      name: '',
      slug: ''
    }
  });

  // Funciones

  // UseEffects
  useEffect(() => {
    if (newCategorie.isLoading) {
      toast.loading('Enviando...');
    }

    if (newCategorie.data) {
      reset();
      newCategorie.reset();
    }
  }, [newCategorie]);

  // Renders
  return (
    <>
      <Modal show={isShowingModal} theme={theme}>
        <CategoriesForm
          theme={theme}
          errors={errors}
          control={control}
          register={register}
          unregister={unregister}
          firtsColumn={firtsColumn}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          createCategories={createCategories}
        />
      </Modal>
    </>
  );
};

export default CategoriesModal;
