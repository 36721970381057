// Librerias
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import InternalError from '../../components/Errors/InternalError';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks

// Componentes

// Imagenes

// Estilos

/**
 * Descripción: Siempre que haya un error en las páginas hijas del Layout se renderizará este componente.
 *
 * Implementación: Se crea una pathless route a la que le pasamos este componente como errorElement. A su vez la pathless route debe encerrar a las rutas que querramos englobar en este error.
 *
 * Bugs: 0.
 */

const ErrorPage = (): JSX.Element => {
  // Estados

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <main className={`errorpage ${theme}`}>
      <div className='errorpage__textbox'>
        <h1>Oops!</h1>
        <h4>Hubo un error interno, intente volver a la página de inicio</h4>
      </div>
      <div className='errorpage__anim'>
        <InternalError />
      </div>
      <Link to='/offers' className='errorpage__link'>
        Volver al inicio
      </Link>
    </main>
  );
};

export default ErrorPage;
