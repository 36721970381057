/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useEffect, useState, useContext, type SetStateAction } from 'react';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks
import { useParentCategories } from '../../hooks/useCategories';

// Componentes
import ParentCategories from '../../components/Categories/Parents/ParentCategories';
import ChildrenCategories from '../../components/Categories/Childrens/ChildrenCategories';
import SubCategories from '../../components/Categories/SubCategories/SubCategories';

// Imagenes

// Estilos

/**
 * Descripción: Aca se muestran y filtran las categorias y las subcategorias
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Categories = (): JSX.Element => {
  // Estados
  const [firtsColumn, setFirtsColumn] = useState<number>(1);
  const [secondColumn, setSecondColumn] = useState<number | null>(null);
  const [showOption, setShowOption] = useState<boolean>(false);
  const [parents, setParents] = useState<[]>([]);
  const [childs, setChilds] = useState<[]>([]);

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks
  const { data, refetch, isSuccess, isLoading } = useParentCategories();

  // Funciones
  const handleFather = (slug: React.SetStateAction<number>): void => {
    setFirtsColumn(slug);
    setSecondColumn(null);
    setShowOption(false);
  };

  const handleSubCategorie = (
    e: React.MouseEvent<HTMLButtonElement>,
    slug: SetStateAction<number | null> = null,
    target?: { nodeName?: string }
  ): void => {
    e.stopPropagation();
    if ((e.target as HTMLButtonElement).nodeName === 'BUTTON') {
      setSecondColumn(slug);
      setShowOption(false);
    }
  };

  const handleUpdate = (): void => {
    void refetch();
  };

  // UseEffects

  // Esta función se encarga de filtrar los datos de subCategories en base al valor de la variable secondColumn
  useEffect(() => {
    // Filtra los datos de subCategories en base al valor de la variable secondColumn
    if (isSuccess && secondColumn) {
      const parentChildren = data?.data?.find(
        (item: { id: number }) => item.id === firtsColumn
      )?.children;
      if (parentChildren) {
        const childSelected = parentChildren?.find(
          ({ id }: { id: number }) => id === secondColumn
        )?.children;
        setChilds(childSelected);
        if (!childSelected) setSecondColumn(null);
      }
    }
  }, [parents, secondColumn, isSuccess, data]);

  // Esta función se encarga de filtrar los datos de categories en base al valor de la variable firtsColumn
  useEffect(() => {
    if (isSuccess) {
      setParents(
        data?.data?.find((item: { id: number }) => item.id === firtsColumn)?.children
      );
    }
  }, [firtsColumn, isSuccess, data]);

  useEffect(() => {
    document.title = 'Categorias - Recursos Humanos | Portinos';
  }, []);

  if (isLoading)
    return (
      <section className={`categories ${theme}`}>
        <div className='categories__header'>
          <h2>Categorías</h2>
        </div>

        <div className='categories__container'>
          <div className='categories__column'>
            <h2>Categorías padres:</h2>

            <p>Cargando</p>
          </div>

          <div className='categories__column'>
            <h2>Subcategorías:</h2>
            <p>Cargando</p>
          </div>
        </div>
      </section>
    );

  // Renders
  return (
    <>
      <section className={`categories ${theme}`}>
        <div className='categories__header'>
          <h2>Categorías</h2>
        </div>

        <div className='categories__container'>
          <ParentCategories
            data={data}
            firtsColumn={firtsColumn}
            handleFather={handleFather}
          />

          <ChildrenCategories
            parents={parents}
            showOption={showOption}
            firtsColumn={firtsColumn}
            secondColumn={secondColumn}
            setShowOption={setShowOption}
            handleSubCategorie={handleSubCategorie}
            handleUpdate={handleUpdate}
          />

          {secondColumn !== null && String(secondColumn) !== '' ? (
            <SubCategories
              childs={childs}
              secondColumn={secondColumn}
              setShowOption={setShowOption}
              handleUpdate={handleUpdate}
            />
          ) : (
            <div />
          )}
        </div>
      </section>
    </>
  );
};

export default Categories;
