/* eslint-disable no-fallthrough */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable default-case */
/* eslint-disable no-console */
import { type LogLevel } from '@azure/msal-browser';
import { MICROSOFT_TENANT_ID, MICROSOFT_CLIENT_ID } from '../constants/auth.constant';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
interface MSALConfig {
  auth: {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    navigateToLoginRequestUrl: boolean;
  };
  cache: {
    cacheLocation: string;
    storeAuthStateInCookie: boolean;
  };
  system?: {
    loggerOptions?: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => void;
    };
  };
}

export const msalConfig: MSALConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${MICROSOFT_TENANT_ID}`,
    redirectUri: '/blank', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //     case LogLevel.Error:
    //       console.error(message);
    //       return;
    //     case LogLevel.Info:
    //       console.info(message);
    //       return;
    //     case LogLevel.Verbose:
    //       console.debug(message);
    //       return;
    //     case LogLevel.Warning:
    //       console.warn(message);
    //     default:
    //     }
    //   },
    // },
  }
};

export const loginRequest = {
  scopes: [] as string[]
};

export const silentRequest = {
  scopes: ['openid', 'profile'],
  loginHint: 'example@domain.net'
};
