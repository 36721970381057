// Librerias
import { useMutation, useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

// Type
import { type IdType, type UseQueryResponse } from '../types/ReactQuery';

// Otros
import api from '../services/api.services';
import { KEY_PROFILES } from '../constants/auth.constant';
import { type UpdateProfile } from './useProfile.types';

export const useUpdateProfile = (): UpdateProfile => {
  const token = sessionStorage.getItem('token');
  const queryClient = useQueryClient();

  const updatedProfile = useMutation({
    mutationFn: async ({
      id,
      formData,
    }: {
      id: IdType;
      formData: Record<string, unknown>;
    }) => {
      return api.profile.updateProfile(token, id, formData);
    },
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_PROFILES]);
      if (Boolean(data?.error) || Boolean(data?.message)) {
        console.error(data.message);
      } else {
        toast.success('Perfil actualizado correctamente');
        void queryClient.refetchQueries([KEY_PROFILES]);
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
      console.error(error);
    },
  });

  const updateProfile = async (
    id: IdType,
    formData: Record<string, unknown>
  ): Promise<void> => {
    updatedProfile.mutate({ id, formData });
  };

  return { updatedProfile, updateProfile };
};

// Esta función permite obtener los datos de una oferta específica.

const useProfile = (id: IdType): Omit<UseQueryResponse, 'filters'> => {
  const token: string | null = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  // Se realiza la consulta a la API para obtener los datos de la oferta.
  const { data, isError, isLoading, error, refetch, isSuccess } = useQuery(
    [KEY_PROFILES, id],
    async () => await api.profile.getProfile(token, id)
  );

  // Función para restablecer la consulta manualmente
  const reset = (): void => {
    void queryClient.resetQueries([KEY_PROFILES, id]); // no hace una goma
  };

  // Se devuelven los datos obtenidos de la consulta a la API.
  return { data, isError, isLoading, error, reset, refetch, isSuccess };
};

export default useProfile;
