/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */

// Librerias
import React from 'react';
import ReactPaginate from 'react-paginate';

// Contextos

// Hooks

// Componentes

// Imagenes

// Estilos

// Types

interface Props {
  pageCount: number;
  currentPage: number;
  itemsPerPage: number;
  info: Info;
  currentItems: Applicant[] | null;
  handlePageChange: (selectedObject: {selected: number}) => void;
}
interface Info {
  total_count: number; 
}
interface Applicant {
  id: number;
  active: boolean;
  names: string;
  last_names: string;
  email: string;
}

/**
 * Descripción: Este componente devuelve la paginacion, cada click es un llamado a la api
 *
 * Implementación: Este componente se importa y debe de recibir los siguentes props
 * info -> la api trae este valor en la informacion info.total_count
 * pageCoun -> Es un estado y se setea el valor con asi setPageCount(Math.ceil(data?.info?.total_count / filters.size));
 * currentPage -> es un estado y funciona en base a la api setCurrentPage(filters.page ? filters.page - 1 : 0);
 * currentItems -> todos los elementos que traiga la respuesta setCurrentItems(data?.data);
 * handlePageChange -> es un handler y setea el valor actual de la pagina en el estado y lo setea en los filtros
 * itemsPerPage -> la api trae este valor en la informacion filters.size
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Paginate = ({
  info,
  pageCount,
  currentPage,
  currentItems,
  itemsPerPage,
  handlePageChange
}: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <>
      {/* Solamente se muestra la paginación cuando los elementos mostrados son menores a la cantidad total de items */}
      {currentItems === null ? null : !currentItems?.length ? null : itemsPerPage <
        info?.total_count ? (
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            previousLabel='<'
            forcePage={currentPage}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            renderOnZeroPageCount={null}
          />
        ) : (
          <div />
        )}
    </>
  );
};

export default Paginate;
