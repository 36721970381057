/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */

// Librerias
import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams, useNavigate, useLocation, type Params } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MdOutlineKeyboardArrowLeft, MdDisabledVisible } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';
import Lottie from 'lottie-react';
import toast from 'react-hot-toast';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks
import { useOffer, useDeleteOffer, useUpdateOffer } from '../../hooks/useOffers';
import { useCreateComment } from '../../hooks/useComments';
import useProfile from '../../hooks/useProfile';

// Componentes
import Loader from '../../assets/animations/lottie-loading.json';
import Error from '../../assets/animations/internal-error.json';
import formatDate from '../../helpers/FormatDate';
import ModalDelete from '../../components/Modals/ModalDelete';
import useModal from '../../hooks/useModal';
import UpdateOffersModal from '../../components/Offers/UpdateOffersModal';
import OffersAplicants, { type Applicant } from '../../components/Offers/OffersAplicants';
import OffersApplicantsColumn from '../../components/Offers/OffersApplicantsColumn';
import RelatedOffers from '../../components/Offers/RelatedOffers';

// Imagenes

// Estilos

// Otros

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const OfferDetails = (): JSX.Element => {
  // Estados
  const [selectedTab, setSelectedTab] = useState<string>('details');
  const [hasModalEdit, setHasModalEdit] = useState<boolean>(true);
  const [applicantSelected, setApplicantSelected] = useState<Applicant | null>(null);

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks
  const params: Readonly<Params<string>> = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = sessionStorage.getItem('userId');
  const session = useProfile(user);
  const state = location.pathname.split('/').filter(Boolean)[1];
  const [isShowingModal, toggleModal] = useModal();

  const { handleDelete, deleteOffers } = useDeleteOffer();
  const { data, isError, isLoading, refetch, isSuccess } = useOffer(String(params.Id));
  const { newComment, createComment } = useCreateComment();
  const { updateOffer } = useUpdateOffer();
  // Funciones
  const today = new Date().toISOString();

  const handleModal = (isEdit: boolean): void => {
    toggleModal();
    if (isEdit) {
      setHasModalEdit(true);
    } else {
      setHasModalEdit(false);
    }
  };

  // UseEffects
  useEffect(() => {
    if (data) {
      if (data?.data?.applicants.length) {
        setApplicantSelected(data.data?.applicants[0]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (newComment.data) {
      newComment.reset();
      void refetch(session.data.token, Number(params.Id));
    }
  }, [newComment]);

  // Esta función se ejecuta cada vez que se actualiza el estado de offers. Utilizamos useEffect para realizar una serie de filtros sobre los datos de offers.
  useEffect(() => {
    const hasNewPost: string | null = sessionStorage.getItem('updatePost');

    if (hasNewPost !== null) {
      void refetch(session.data.token, Number(params.Id));
      sessionStorage.setItem('updatePost', 'false');
    }
  }, [isShowingModal]);

  useEffect(() => {
    if (deleteOffers.isLoading) {
      toast.loading('Enviando...');
    }

    if (deleteOffers.data) {
      toggleModal();
      toast.dismiss();
      deleteOffers.reset();
      navigate(`/offers/${state}`);
    }
  }, [deleteOffers]);

  return (
    <>
      {isLoading && session.isLoading && (
        <section className={`details ${theme}`}>
          <div className='details__header'>
            <Link to={`/offers/${state}`} state={{ from: location }} replace>
              <MdOutlineKeyboardArrowLeft />
            </Link>

            <div className='details__header__title'>
              <span>Cargando</span>
              <h2>Cargando...</h2>
              <span>
                Cargando.. <BsDot /> Cargando...
              </span>
            </div>
          </div>

          <div className='details__tabs'>
            <ul>
              {/* Detalles de la oferta */}
              <li
                className={selectedTab === 'details' ? 'selected' : ''}
                onClick={() => {
                  setSelectedTab('details');
                }}
              >
                <p>Detalles de la oferta</p>
                {selectedTab === 'details' ? (
                  <motion.div className='underline' layoutId='underline' />
                ) : null}
              </li>

              {/* aplicants */}
              <li
                className={selectedTab === 'aplicants' ? 'selected' : ''}
                onClick={() => {
                  setSelectedTab('aplicants');
                }}
              >
                <p>Candidatos</p>
                {selectedTab === 'aplicants' ? (
                  <motion.div className='underline' layoutId='underline' />
                ) : null}
              </li>
            </ul>
          </div>

          <AnimatePresence mode='wait'>
            <motion.div
              key={selectedTab || 'empty'}
              className='offers__container'
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className='offers__loader'>
                <Lottie className='lottie' animationData={Loader} />
              </div>
            </motion.div>
          </AnimatePresence>
        </section>
      )}

      {isError && (
        <section className={`details ${theme}`}>
          <div className='details__header'>
            <Link to={`/offers/${state}`} state={{ from: location }} replace>
              <MdOutlineKeyboardArrowLeft />
            </Link>

            <div className='details__header__title'>
              <span>Error</span>
              <h2>{data.error.message}</h2>
              <span>
                {data.error.statusCode} <BsDot /> {data.error.error}
              </span>
            </div>
          </div>

          <AnimatePresence mode='wait'>
            <motion.div
              key={selectedTab || 'empty'}
              className='offers__container'
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className='offers__error'>
                <Lottie className='lottie' animationData={Error} />
              </div>
            </motion.div>
          </AnimatePresence>
        </section>
      )}

      {isSuccess && session.isSuccess && (
        <>
          {hasModalEdit ? (
            <UpdateOffersModal
              theme={theme}
              actualData={data}
              handleClick={toggleModal}
              isShowingModal={isShowingModal}
            />
          ) : (
            <ModalDelete
              setCleanData={() => null}
              toggleModal={toggleModal}
              isShowingModal={isShowingModal}
              isLoading={deleteOffers.isLoading}
              handleAction={async (): Promise<void> => handleDelete(params.Id || null)}
            />
          )}

          <section className={`details ${theme}`}>
            <div className='details__header'>
              <Link to={`/offers/${state}`} state={{ from: location }} replace>
                <MdOutlineKeyboardArrowLeft />
              </Link>

              <div className='details__header__title'>
                <span>{data.data?.categories[0]?.name ?? ''}</span>
                <h2>{data.data?.title ?? ''}</h2>
                <span>
                  {data.data?.categories[2]?.name ?? ''}
                  {data.data?.categories[2]?.name && <BsDot />}
                  {data.data?.categories[1]?.name ?? ''}
                </span>
              </div>

              {data.data?.end_date < today && (
                <div className='details__alert disabled'>
                  <MdDisabledVisible />
                  <p>Esta búsqueda ha expirado.</p>
                  <span>Ya excedió la fecha límite, no se mostrará al público.</span>
                </div>
              )}

              {!data.data?.active && (
                <div className='details__alert error'>
                  <BiError />
                  <p>Esta búsqueda se encuentra desactivada.</p>
                  <span>Está inactiva, no se mostrará al público.</span>
                </div>
              )}
            </div>

            <div className='details__tabs'>
              <ul>
                {/* Detalles de la oferta */}
                <li
                  className={selectedTab === 'details' ? 'selected' : ''}
                  onClick={() => {
                    setSelectedTab('details');
                  }}
                >
                  <p>Detalles de la oferta</p>
                  {selectedTab === 'details' ? (
                    <motion.div className='underline' layoutId='underline' />
                  ) : null}
                </li>

                {/* aplicants */}
                <li
                  className={selectedTab === 'aplicants' ? 'selected' : ''}
                  onClick={() => {
                    setSelectedTab('aplicants');
                  }}
                >
                  <p>Candidatos</p>
                  {selectedTab === 'aplicants' ? (
                    <motion.div className='underline' layoutId='underline' />
                  ) : null}
                </li>
              </ul>
            </div>

            <AnimatePresence mode='wait'>
              <motion.div
                key={selectedTab || 'empty'}
                className='details__container'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {/* Activas */}
                {selectedTab === 'aplicants' ? (
                  <>
                    <OffersAplicants
                      applicants={data.data?.applicants}
                      applicantSelected={applicantSelected}
                      setApplicantSelected={setApplicantSelected}
                    />
                    {data.data?.applicants.length > 0 && (
                      <OffersApplicantsColumn
                        theme={theme}
                        refetch={refetch}
                        session={session.data}
                        newComment={newComment}
                        createComment={createComment}
                        applicantSelected={applicantSelected}
                      />
                    )}{' '}
                  </>
                ) : null}

                {/* Detalles de la oferta */}
                {selectedTab === 'details' ? (
                  <>
                    <div className='details__column'>
                      <div className='details__content'>
                        <h2>Sobre esta oferta:</h2>

                        <div dangerouslySetInnerHTML={{ __html: data.data?.content }} />
                      </div>
                    </div>

                    <div className='details__column'>
                      <div className='details__content'>
                        <h3>Info adicional:</h3>

                        <div className='details__content__grid'>
                          <p>
                            <b>Status:</b>{' '}
                            <span data-active={data.data?.active}>
                              {data.data?.active ? 'Activo' : 'Inactivo'}
                            </span>
                          </p>
                          <p>
                            <b>Seniority:</b>{' '}
                            {
                              data.data?.categories.find(
                                (item: { parent: number }) => item.parent === 3
                              )?.name
                            }
                          </p>
                          <p>
                            <b>Tipo:</b>{' '}
                            {
                              data.data?.categories.find(
                                (item: { parent: number }) => item.parent === 2
                              )?.name
                            }
                          </p>
                          <p>
                            <b>Creada:</b> {formatDate(data.data?.createdAt, true)}
                          </p>
                          <p>
                            <b>Aplicantes:</b> {data.data?.applicants.length}
                          </p>
                          <p>
                            <b>Finalizada:</b> {formatDate(data.data?.end_date, true)}
                          </p>
                        </div>
                      </div>

                      <div className='details__content'>
                        <h3>Ajustes:</h3>

                        <div className='details__content__row'>
                          <button
                            onClick={() => {
                              handleModal(true);
                            }}
                          >
                            Editar
                          </button>
                          {session.data.role === 0 ? (
                            <button
                              onClick={() => {
                                handleModal(false);
                              }}
                            >
                              Eliminar
                            </button>
                          ) : (
                            <button disabled title='No tienes autorizacion para esto'>
                              Eliminar
                            </button>
                          )}
                          <button
                            onClick={async () =>
                              updateOffer(
                                data.data?.id,
                                JSON.stringify({ active: !data.data?.active })
                              )
                            }
                            data-active={data.data?.active}
                          >
                            {data.data?.active ? 'Desactivar' : 'Activar'}
                          </button>
                        </div>
                      </div>

                      <RelatedOffers categorie={data.data?.categories[0]?.id} />
                    </div>
                  </>
                ) : null}
              </motion.div>
            </AnimatePresence>
          </section>
        </>
      )}
    </>
  );
};

export default OfferDetails;
