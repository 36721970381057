// Librerias
import React, { useContext, useEffect } from 'react';
import { IoIosClose } from 'react-icons/io';
import { BiTrash } from 'react-icons/bi';
import Lottie from 'lottie-react';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Hooks

// Componentes
import Modal from '../Modal';
import Spinner from '../../assets/animations/lottie-spinner.json';

// Imagenes

// Estilos

// Types
interface Props {
  title: string;
  subtitle: string;
  isLoading: boolean;
  isShowingModal: boolean;
  toggleModal: () => void;
  handleAction: () => void;
  setCleanData: ({ data }: { data: null }) => void;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Este modal solo se usa cuando se va a eliminar alguna publicacion.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const ModalDelete = ({
  title,
  subtitle,
  isShowingModal,
  toggleModal,
  handleAction,
  setCleanData,
  isLoading
}: Props): JSX.Element => {
  // Estados

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks

  // Funciones

  // UseEffects
  useEffect(() => {
    return () => {
      setCleanData({
        data: null
      });
    };
  }, []);

  return (
    <>
      <Modal show={isShowingModal} theme={theme} prompt>
        <div className='modal__container'>
          <div className='modal__header'>
            <div>
              <BiTrash />
            </div>
            <button onClick={toggleModal}>
              <IoIosClose />
            </button>
          </div>

          <div className='modal__body'>
            <p>{title}</p>
            <span>{subtitle}, esta acción no se puede deshacer.</span>
          </div>

          <div className='modal__footer'>
            <button onClick={toggleModal}>Cancelar</button>

            {isLoading ? (
              <button>
                <Lottie animationData={Spinner} />
                Cargando...
              </button>
            ) : (
              <button onClick={handleAction}>Eliminar</button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

ModalDelete.defaultProps = {
  title: '¿Desea eliminar esta publicación?',
  subtitle: '¿Está seguro que desea eliminar esta publicación?',
  setCleanData: () => null
};

export default ModalDelete;
