/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

// Librerias
import React, { useEffect, useState } from 'react';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ReactSelect from 'react-select';

// Contextos

// Hooks
import { toast } from 'react-hot-toast';
import { useUpdateUser } from '../../hooks/useUser';

// Componentes
import Modal from '../Modal';
import { type ThemeContextType } from '../../types/ThemeContext';

// Imagenes

// Estilos

// Types
import { type HookFormInterface } from '../../types/HookForm';

interface Props {
  theme: ThemeContextType['theme'];
  handleClick: () => void;
  isShowingModal: boolean;
  actualUserData: User;
}
interface User {
  id: string;
  email: string;
  job: string;
  role: number;
  active: string | boolean;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const UpdateUserForm = ({
  theme,
  handleClick,
  isShowingModal,
  actualUserData,
}: Props): JSX.Element => {
  // Estados
  const [statusSelected, setStatusSelected] = useState(actualUserData.active);

  // Contextos

  // Hooks
  const { updatedUser, updateUser } = useUpdateUser();

  const {
    register,
    handleSubmit,
    reset,
    unregister,
    control,
    formState: { errors },
  }: HookFormInterface = useForm({
    mode: 'all',
  });

  // Funciones
  const emailRegistration = register('email');
  const jobRegistration = register('job');

  const rolesOptions = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Recursos Humanos' },
  ];

  const statusOptions = [
    { value: true, label: '🟢 Activo' },
    { value: false, label: '🔴 Desactivado' },
  ];

  const onSubmit = (data: FieldValues): void => {
    const newData = {
      active: statusSelected || actualUserData.active,
      role: data.role || actualUserData.role,
      job: data.job || actualUserData.job,
      email: data.email || actualUserData.email,
    };

    void updateUser(actualUserData.id, JSON.stringify(newData));
  };

  // UseEffects
  useEffect(() => {
    if (updatedUser.isLoading) {
      toast.dismiss();
      toast.loading('Enviando');
    }

    if (updatedUser.data) {
      updatedUser.reset();
      reset();
      handleClick();
    }
  }, [updatedUser]);

  useEffect(() => {
    return () => {
      reset();
      updatedUser.reset();
      unregister('active');
      unregister('email');
      unregister('job');
      unregister('role');
    };
  }, [isShowingModal]);

  // Renders
  return (
    <>
      <Modal show={isShowingModal} theme={theme}>
        <form className={`userform ${theme}`} onSubmit={handleSubmit(onSubmit)}>
          <div className='userform__header'>
            <h2>Actualizar usuario:</h2>
            <button onClick={handleClick}>
              <AiOutlineCloseCircle />
            </button>
          </div>

          {/* Información basica */}
          <div className='userform__row'>
            <div className='userform__column'>
              <h3>Información básica:</h3>
              <p>Actualice la información básica del usuario.</p>
            </div>

            <div className='userform__form'>
              <label htmlFor='active'>
                Activar/Desactivar usuario:
                <Controller
                  name='active'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      isSearchable={false}
                      options={statusOptions}
                      loadingMessage={() => 'Loading...'}
                      classNamePrefix='offersmodal__select'
                      className={`offersmodal__select ${theme}`}
                      // onChange={(val: SingleValue<{ value: boolean; label: string }>) => {
                      onChange={(val: any) => {
                        onChange(val?.value); // boolean
                        setStatusSelected(String(val?.value));
                      }}
                      value={
                        statusOptions.find((s) => s.value === value) != null ||
                        statusOptions.find((s) => s.value === actualUserData.active)
                      }
                    />
                  )}
                />
                {errors.active != null && (
                  <span role='alert'>{errors.active?.message}</span>
                )}
              </label>

              <label htmlFor='role'>
                Rol:
                <Controller
                  name='role'
                  control={control}
                  render={({ field: { onChange } }) => (
                    <ReactSelect
                      isSearchable={false}
                      options={rolesOptions}
                      loadingMessage={() => 'Loading...'}
                      classNamePrefix='offersmodal__select'
                      defaultValue={rolesOptions.find(
                        (option) => option.value === actualUserData.role
                      )}
                      className={`offersmodal__select ${theme} ${
                        errors.role != null ? 'error' : ''
                      }`}
                      onChange={(selectedOption) => {
                        onChange(selectedOption?.value);
                      }}
                    />
                  )}
                />
                {errors.role != null && <span role='alert'>{errors.role?.message}</span>}
              </label>

              <label htmlFor='job'>
                Puesto:
                <input
                  type='text'
                  id='job'
                  placeholder='Ej: Reclutador'
                  autoComplete='new-password'
                  autoCapitalize='true'
                  autoSave='false'
                  defaultValue={actualUserData.job}
                  className={errors.job != null ? 'error' : ''}
                  {...jobRegistration}
                />
                {errors.job != null && <span role='alert'>{errors.job?.message}</span>}
              </label>

              <label htmlFor='email'>
                Correo:
                <input
                  type='email'
                  id='email'
                  placeholder='Escriba el correo Portinero'
                  autoComplete='new-password'
                  autoCapitalize='true'
                  defaultValue={actualUserData.email}
                  autoSave='false'
                  className={errors.email != null ? 'error' : ''}
                  {...emailRegistration}
                />
                {errors.email != null && (
                  <span role='alert'>{errors?.email?.message}</span>
                )}
              </label>
            </div>
          </div>

          {/* Footer */}
          <div className='userform__footer'>
            <button onClick={handleClick}>Cancelar</button>
            <button type='submit'>Actualizar usuario</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UpdateUserForm;
