/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

// Contextos

// Hooks
import { useUpdateOffer } from '../../hooks/useOffers';

// Componentes
import UpdateOffersForm from './UpdateOffersForm';
import Modal from '../Modal';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  theme: ThemeContextType['theme'];
  actualData: ActualDataType;
  isShowingModal: boolean;
  handleClick: () => void;
}
interface ActualDataType {
  status: number;
  data: {
    active: boolean;
    applicants: [];
    categories: [];
    content: string;
    createdAt: string;
    end_date: string;
    id: number;
    short_description: string;
    sub_categories: [];
    title: string;
    updatedAt: string;
    user: {
      id: number;
      avatar: string;
      firstName: string;
      lastName: string;
    };
    userId: number;
  };
}

/**
 * Descripción: Modal que contiene el formulario para hacer una oferta/busqueda
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const UpdateOffersModal = ({
  theme,
  actualData,
  handleClick,
  isShowingModal,
}: Props): JSX.Element => {
  // Estados

  // Contextos
  // Hooks
  const { updateOffer, updatedOffer } = useUpdateOffer();

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Funciones

  // UseEffects
  useEffect(() => {
    if (updatedOffer.isLoading) {
      toast.dismiss();
      toast.loading('Enviando...');
    }

    if (updatedOffer.data) {
      reset();
      updatedOffer.reset();
      handleClick();
    }
  }, [updatedOffer]);

  useEffect(() => {
    return () => {
      updatedOffer.reset();
    };
  }, []);

  // Renders
  return (
    <>
      <Modal show={isShowingModal} theme={theme}>
        <UpdateOffersForm
          theme={theme}
          errors={errors}
          control={control}
          register={register}
          updateOffer={updateOffer}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          actualData={actualData.data}
        />
      </Modal>
    </>
  );
};

export default UpdateOffersModal;
