// Librerias
import React, { type ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

// Otros

/**
 * Descripcion del helper: Esto protege las rutas
 */

// Esta función me permite verificar si estoy logeado y me redirige a la página de login si no es así.
const RequireAuth = ({ children }: { children: ReactNode }): ReactNode => {
  const token: string | null = sessionStorage.getItem('token');
  const userId: string | null = sessionStorage.getItem('userId');
  const location = useLocation(); // Obtengo la ubicación actual

  if (token === null || userId === null) {
    // Si no hay un token en la sesión, entonces... Me redirige a la página de login guardando de donde vengo
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children; // Si estoy logeado, devuelvo el contenido que recibo como parámetro.
};

export default RequireAuth;
