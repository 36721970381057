/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */

// Librerias
import { useState } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from 'react-query';
import toast from 'react-hot-toast';

// Otros
import api from '../services/api.services';
import { KEY_OFFERS } from '../constants/offers.constant';

// Types
import { type IdType, type TokenType, type UseQueryResponse } from '../types/ReactQuery';

interface DeleteResponse {
  ids: string;
}

interface DeleteMultiplesTypes {
  deleteMultiplesOffers: UseMutationResult<DeleteResponse, unknown, string, unknown>;
  handleDelete: (ids: string) => Promise<any>;
}

interface CreateTypes {
  newOffer: UseMutationResult<any, unknown, any, unknown>;
  createOffers: (body: string) => Promise<void>;
}

interface DeleteOffers {
  handleDelete: (id: IdType) => Promise<void>;
  deleteOffers: UseMutationResult<any, unknown, any, unknown>;
}

interface UpdateOffer {
  updatedOffer: UseMutationResult<any, unknown, { id: IdType; body: string }, unknown>;
  updateOffer: (id: IdType, body: string) => Promise<void>;
}

/**
 * Descripcion del hook: Este hook resuelve las mutaciones y llamadas al servicio con react query
 *
 * Implementacion: Descripción sobre cómo puedes implementar el hook.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

// Esta función se encarga de actualizar una oferta
export const useUpdateOffer = (): UpdateOffer => {
  const token: TokenType = sessionStorage.getItem('token');

  // Se obtiene la sesión del contexto de autenticación

  // Se obtiene el cliente de consultas
  const queryClient = useQueryClient();

  // Se crea una mutación para actualizar la oferta
  const updatedOffer = useMutation({
    mutationFn: async ({ id, body }: { id: IdType; body: string }) => {
      // Se realiza la petición al servidor para actualizar la oferta con los datos proporcionados
      return await api.offers.update(token, id, body);
    },

    // Si la petición es exitosa se invalida las consultas relacionadas con las ofertas y se muestra un mensaje de éxito o error dependiendo del resultado obtenido del servidor
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_OFFERS]);

      if (data.error) {
        toast.dismiss();
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message);
      }
    },

    // Si ocurre algún error en la petición se muestra un mensaje de error al usuario informando del problema ocurrido
    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    },
  });

  // Esta función recibe el identificador y los datos a actualizar de la oferta y ejecuta la mutación creada anteriormente para realizar la petición al servidor
  const updateOffer = async (id: IdType, body: string): Promise<void> => {
    updatedOffer.mutate({ id, body });
  };

  return { updatedOffer, updateOffer };
};

// Esta función se encarga de crear una oferta
export const useCreateOffer = (): CreateTypes => {
  const token: TokenType = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const newOffer = useMutation<any, unknown, string, unknown>({
    mutationFn: async (body: string) => await api.offers.create(token, body),

    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_OFFERS]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Oferta creada correctamente! ❤️');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    },
  });

  const createOffers = async (body: string): Promise<void> => {
    newOffer.mutate(body);
  };

  return { newOffer, createOffers };
};

// Esta función se encarga de eliminar una oferta
export const useDeleteOffer = (): DeleteOffers => {
  const token: TokenType = sessionStorage.getItem('token');

  // Se obtiene la sesión del usuario desde el contexto de autenticación

  // Se obtiene el cliente de consultas para realizar refetchs
  const queryClient = useQueryClient();

  // Se crea una mutación para eliminar la oferta
  const deleteOffers = useMutation({
    mutationFn: async (id: IdType) => await api.offers.delete(token, id),
    onSuccess: (data) => {
      // Cuando la operación es exitosa, se realiza un refetch a las ofertas
      void queryClient.invalidateQueries([KEY_OFFERS]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Eliminado correctamente!');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    },
  });

  // Esta función se encarga de ejecutar la mutación para eliminar una oferta en particular
  const handleDelete = async (id: IdType): Promise<void> => {
    deleteOffers.mutate(id);
  };

  return { handleDelete, deleteOffers };
};

export const useDeleteMultiplesOffer = (): DeleteMultiplesTypes => {
  const token: TokenType = sessionStorage.getItem('token');

  // Se obtiene la sesión del usuario desde el contexto de autenticación

  // Se obtiene el cliente de consultas para realizar refetchs
  const queryClient = useQueryClient();

  // Se crea una mutación para eliminar la oferta
  const deleteMultiplesOffers = useMutation({
    mutationFn: async (ids: string) => await api.offers.deleteMultiples(token, ids),
    onSuccess: (data) => {
      // Cuando la operación es exitosa, se realiza un refetch a las ofertas
      void queryClient.invalidateQueries([KEY_OFFERS]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success(data.message);
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    },
  });

  // Esta función se encarga de ejecutar la mutación para eliminar una oferta en particular
  const handleDelete = async (ids: string): Promise<any> => {
    deleteMultiplesOffers.mutate(ids);
  };

  return { handleDelete, deleteMultiplesOffers };
};

// Esta función permite obtener los datos de una oferta específica.
export const useOffer = (id: IdType): Omit<UseQueryResponse, 'filters'> => {
  // Se obtiene el contexto de autenticación.
  const token: TokenType = sessionStorage.getItem('token');

  // Se realiza la consulta a la API para obtener los datos de la oferta.
  const { data, isError, isLoading, error, refetch, isSuccess } = useQuery(
    [KEY_OFFERS, id],
    async () => await api.offers.getOffer(token, id)
  );

  // Se devuelven los datos obtenidos de la consulta a la API.
  return { data, isError, isLoading, error, refetch, isSuccess };
};

// Trae todas las ofertas
const useOffers = (): UseQueryResponse => {
  const token: TokenType = sessionStorage.getItem('token');

  // Esta línea de código define un estado inicial para los filtros
  const [filters, setFilters] = useState({
    title: null, // Filtra por el contenido del título
    categories: null, // Filtra por ID de las categorias pasadas (hay que pasarlas separadas por comas)
    from: null, // Fecha desde la que queremos ver las ofertas
    to: null, // Muestra las ofertas disponibles o no disponibles
    active: true, // Muestra las ofertas disponibles o no disponibles
    expired: false, // Muestras o no las ofertas vencidas
    page: 1, // Página actual
    size: 10, // Tamaño de la página
    order: 'created-at', // Ordenar items por: "created-at", "updated-at", "end-date", "title"
    sort: 'desc', // Ordenar por ascendente o descendente
  });

  // Esta línea de código obtiene los datos, el estado de carga, el error y la función refetch desde la consulta.
  const { data, isLoading, error, isError, refetch, isSuccess } = useQuery(
    [KEY_OFFERS, JSON.stringify(filters)], // Esta clave y los filtros se pasan como parámetros a la consulta.
    async () => await api.offers.getAllOffers(token, filters) // Esta función asíncrona obtiene todas las ofertas usando el token de sesión y los filtros especificados anteriormente.
  );

  return { data, isLoading, isSuccess, error, isError, refetch, filters, setFilters };
};

export default useOffers;
