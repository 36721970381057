/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */
import React, { type FC, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormHandleSubmit,
  type UseFormRegister,
  type FieldErrors,
} from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { type FormData } from '../Childrens/UpdateCategoriesForm';
// Contextos

// Hooks
import { useParentCategories, useCategories } from '../../../hooks/useCategories';

// Componentes
import CreateSlug from '../../../helpers/CreateSlug';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../../types/ThemeContext';
import { type IdType, type BodyType } from '../../../types/ReactQuery';

interface Props {
  errors: FieldErrors<updateCategory>;
  actualData: { data: Category };
  handleClick: () => void;
  theme: ThemeContextType['theme'];
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  updateSubCategorie: (id: IdType, body: BodyType) => Promise<void>;
}
interface Category {
  id: string;
  name: string;
  parent?: string;
  slug?: string;
}
interface Option {
  value: number | string;
  label: string;
}

interface GroupedOption {
  id: number;
  label: string;
  options: Option[];
}
interface updateCategory {
  updateName: string;
  updateSlug: string;
}
/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const UpdateCategoriesForm = ({
  theme,
  errors,
  control,
  register,
  actualData,
  handleClick,
  handleSubmit,
  updateSubCategorie,
}: Props): JSX.Element => {
  // Estados
  const [groupedOptions, setGroupedOptions] = useState<GroupedOption[]>([]);

  const [parentSelected, setParentSelected] = useState<any>(actualData.data.parent);
  // parentSelected: [{label: string; value: number}] / actualData.data.parent: number
  const [slugValue, setSlugValue] = useState<string>('');

  // Contextos
  const parentCategories = useParentCategories();
  const categories = useCategories();

  // Hooks

  // Funciones
  const updateName = register('updateName', {});

  const updateSlug = register('updateSlug', {});

  const onSubmit = (data: FormData): void => {
    const newData: BodyType = {
      parent: parentSelected?.value || actualData.data.parent,
      name: data.updateName || actualData.data.name,
      slug: slugValue || actualData.data.slug,
    };
    void updateSubCategorie(actualData.data.id, JSON.stringify(newData));
    sessionStorage.setItem('updateCategories', 'true');
  };

  const handleChange = (e: { target: { value: string } }): void => {
    setSlugValue(CreateSlug(e.target.value));
  };

  const formatGroupLabel: FC<GroupedOption> = (data: GroupedOption): JSX.Element => (
    <div className={`groupselect ${theme}`}>
      <span className='groupselect__label'>{data?.label}</span>
      <span className='groupselect__badge'>{data?.options.length}</span>
    </div>
  );

  // UseEffects
  useEffect(() => {
    if (categories.isSuccess) {
      groupedOptions.forEach((parent: GroupedOption) => {
        categories.data?.data
          .filter(
            (categorie: { parent: string | number }) => categorie.parent === parent.id
          )
          .forEach((categorie: { id: string; name: string }) =>
            parent.options.push({ value: categorie.id, label: categorie.name })
          );
      });
    }

    if (actualData.data) {
      const selectedValues = categories.data?.data
        .filter((categorie: { id: string }) => categorie.id === actualData.data.parent)
        .map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        }));
      setParentSelected(selectedValues);
    }
  }, [groupedOptions, categories.isSuccess]);

  useEffect(() => {
    if (parentCategories.isSuccess) {
      const newGroupedOptions: GroupedOption[] = [];

      parentCategories.data?.data.forEach((itemA: { id: number; name: string }) => {
        if (
          !newGroupedOptions.find(
            (itemB: { label: string }) => itemB.label === itemA.name
          )
        ) {
          newGroupedOptions.push({ id: itemA.id, label: itemA.name, options: [] });
          setGroupedOptions(newGroupedOptions);
        }
      });
    }
  }, [parentCategories.isSuccess]);

  // Renders
  return (
    <form className={`categoriesmodal ${theme}`} onSubmit={handleSubmit(onSubmit)}>
      <div className='categoriesmodal__header'>
        <h2>Actualizar Subcategoría:</h2>
        <button onClick={handleClick}>
          <AiOutlineCloseCircle />
        </button>
      </div>

      {/* Información basica */}
      <div className='categoriesmodal__row'>
        <div className='categoriesmodal__column'>
          <h3>Información de la subcategoría:</h3>
          <p>
            Escriba la información básica sobre la subcategoría, esto se usa para
            categorizar las ofertas y demás.
          </p>
        </div>

        <div className='categoriesmodal__form'>
          <div className='categoriesmodal__form__row'>
            <p>
              Categoría padre:
              <Controller
                name='updateParent'
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    isDisabled={true}
                    isSearchable={false}
                    options={groupedOptions}
                    classNamePrefix='categoriesmodal__select'
                    className={`categoriesmodal__select ${theme}`}
                    value={parentSelected}
                    defaultValue={parentSelected}
                    onChange={(value) => {
                      setParentSelected(value);
                    }}
                    formatGroupLabel={formatGroupLabel}
                  />
                )}
              />
            </p>
          </div>

          <p>
            Nombre:
            <input
              {...updateName}
              type='text'
              name='updateName'
              onChange={handleChange}
              // defaultValue={actualData.data.name}
              placeholder={actualData.data.name}
              className={errors.updateName ? 'error' : ''}
            />
            {errors.updateName && <span role='alert'>{errors.updateName?.message}</span>}
          </p>

          <p>
            Slug:
            <input
              {...updateSlug}
              readOnly
              type='text'
              name='updateSlug'
              value={slugValue}
              defaultValue={actualData.data.slug}
              placeholder={actualData.data.slug}
              className={errors.updateSlug ? 'error' : ''}
              // Ta roto
            />
            {errors.updateSlug && <span role='alert'>{errors.updateSlug?.message}</span>}
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className='categoriesmodal__footer'>
        <button onClick={handleClick}>Cancelar</button>
        <button type='submit' title='Post'>
          Publicar
        </button>
      </div>
    </form>
  );
};

export default UpdateCategoriesForm;
