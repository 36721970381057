/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { BiMessageSquareEdit, BiTrash } from 'react-icons/bi';

// Types
interface Props {
  childs: Child[];
  handleModal: ModalAction;
}
interface Child {
  id: null;
  name: string;
}
type ModalAction = (
  event: React.MouseEvent<HTMLButtonElement>,
  type: string,
  id: null
) => void;

const SubCategoriesTable = ({ childs, handleModal }: Props): JSX.Element => {
  // Renders
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {!childs?.length ? (
            <tr>
              <td>
                <AiOutlineWarning />{' '}
              </td>

              <td>No hay subcategorías asiganadas.</td>

              <td />
            </tr>
          ) : (
            <>
              {childs?.map((item: Child) => (
                <tr key={item.id}>
                  <td>{item.id}</td>

                  <td>{item.name}</td>

                  <td>
                    <p>
                      <button
                        onClick={(e) => {
                          handleModal(e, 'edit', item.id);
                        }}
                      >
                        <BiMessageSquareEdit />
                      </button>
                      <button
                        onClick={(e) => {
                          handleModal(e, 'delete', item.id);
                        }}
                      >
                        <BiTrash />
                      </button>
                    </p>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default SubCategoriesTable;
