/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable import/named */
/* eslint-disable no-param-reassign */
import {
  USERS_GET,
  AUTH_LOGIN,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  GET_PROFILE,
  AUTH_RECOVERY,
  PROFILE_UPDATE,
  AUTH_CHANGE_PASSWORD
} from '../constants/auth.constant';

import {
  GET_OFFERS,
  CREATE_OFFERS,
  DELETE_OFFERS,
  UPDATE_OFFERS
} from '../constants/offers.constant';

import {
  GET_PARENT_CATEGORIES,
  CREATE_PARENT_CATEGORIES,
  DELETE_PARENT_CATEGORIES,
  UPDATE_PARENT_CATEGORIES,
  GET_CATEGORIES,
  CREATE_CATEGORIES,
  DELETE_CATEGORIES,
  UPDATE_CATEGORIES,
  GET_SUB_CATEGORIES,
  CREATE_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORIES,
  DELETE_SUB_CATEGORIES
} from '../constants/categories.constant';

import { CREATE_COMMENTS, DELETE_COMMENTS } from '../constants/comments.constant';

import { GET_APPLICANTS, UPDATE_APPLICANTS } from '../constants/applicants.constant';

import DeleteEmptyKeys from '../helpers/DeleteEmptyKeys';

// Types
type BodyType = Record<string, unknown> | string;
type TokenType = string | null;
type IdType = string | null;
type FilterType = Record<string, unknown> | any;

// Esta función realiza una llamada a una API externa y devuelve los datos obtenidos.
async function callApi(
  endpoint: string | URL | RequestInfo,
  options: Record<string, unknown>
): Promise<Array<Record<string, unknown>> | Record<string, unknown> | any> {
  // Se agregan cabeceras al objeto de opciones pasado como parámetro.
  options.headers = {
    Accept: 'application/json',
    ...(options.headers as Record<string, unknown>)
  };

  // Se guarda la URL de la API en una variable.
  const url = endpoint;

  // Se realiza la llamada a la API y se obtiene una respuesta.
  const response = await fetch(url, options);

  // Se convierte la respuesta a formato JSON.
  const data = await response.json();

  if (!response.ok || typeof data.error === 'object') {
    console.error(data);

    if (typeof data === 'object') {
      if (data.error.statusCode === 409) {
        throw new Error('El email debe ser unico');
      }

      throw new Error(data.error.message);
    } else {
      throw new Error(data);
    }
  }

  // Se devuelven los datos obtenidos de la API externa.
  return data;
}

const api = {
  login: {
    async singIn(body: BodyType) {
      return await callApi(AUTH_LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        redirect: 'follow'
      });
    },
    async recovery(body: BodyType) {
      return await callApi(AUTH_RECOVERY, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        redirect: 'follow'
      });
    },
    async changePass(body: BodyType) {
      return await callApi(AUTH_CHANGE_PASSWORD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        redirect: 'follow'
      });
    }
  },
  applicants: {
    async getAllApplicants(token: TokenType, filters: FilterType) {
      DeleteEmptyKeys(filters);
      const url = new URL(GET_APPLICANTS);
      url.search = new URLSearchParams(filters).toString();
      return await callApi(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async getApplicant(token: TokenType, id: IdType | undefined) {
      return await callApi(`${GET_APPLICANTS}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async updateApplicant(token: TokenType, id: IdType, body: BodyType) {
      return await callApi(`${UPDATE_APPLICANTS}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    }
  },
  comments: {
    async createComment(token: TokenType, body: BodyType) {
      return await callApi(`${CREATE_COMMENTS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body),
        redirect: 'follow'
      });
    },
    async deleteComment(token: TokenType, id: IdType) {
      return await callApi(`${DELETE_COMMENTS}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    }
  },
  offers: {
    async getAllOffers(token: TokenType, filters: FilterType) {
      DeleteEmptyKeys(filters);
      const url = new URL(GET_OFFERS);
      url.search = new URLSearchParams(filters).toString();
      return await callApi(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async getOffer(token: TokenType, id: IdType) {
      return await callApi(`${GET_OFFERS}/${id}/applicants`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async create(token: TokenType, body: BodyType) {
      return await callApi(CREATE_OFFERS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async update(token: TokenType, id: IdType, body: BodyType) {
      return await callApi(`${UPDATE_OFFERS}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async delete(token: TokenType, id: IdType) {
      return await callApi(`${DELETE_OFFERS}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async deleteMultiples(token: TokenType, body: BodyType) {
      return await callApi(`${DELETE_OFFERS}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    }
  },
  parentCategories: {
    async getAllCategories(token: TokenType) {
      return await callApi(GET_PARENT_CATEGORIES, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async getOneCategorie(token: TokenType, id: IdType) {
      return await callApi(`${GET_PARENT_CATEGORIES}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async create(body: BodyType, token: TokenType) {
      return await callApi(CREATE_PARENT_CATEGORIES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async update(id: IdType, body: BodyType, token: TokenType) {
      return await callApi(`${UPDATE_PARENT_CATEGORIES}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async deleteUser(id: IdType, token: TokenType) {
      return await callApi(`${DELETE_PARENT_CATEGORIES}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    }
  },
  categories: {
    async getAllCategories(token: TokenType) {
      return await callApi(GET_CATEGORIES, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async getOneCategorie(token: TokenType, id: IdType) {
      return await callApi(`${GET_CATEGORIES}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async create(token: TokenType, body: BodyType) {
      return await callApi(CREATE_CATEGORIES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async update(token: TokenType, id: IdType, body: BodyType) {
      return await callApi(`${UPDATE_CATEGORIES}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async delete(token: TokenType, id: IdType) {
      return await callApi(`${DELETE_CATEGORIES}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    }
  },
  subCategories: {
    async getAll(token: TokenType) {
      return await callApi(GET_SUB_CATEGORIES, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async getOne(token: TokenType, id: IdType) {
      return await callApi(`${GET_SUB_CATEGORIES}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async create(token: TokenType, body: BodyType) {
      return await callApi(CREATE_SUB_CATEGORIES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async update(token: TokenType, id: IdType, body: BodyType) {
      return await callApi(`${UPDATE_SUB_CATEGORIES}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async delete(token: TokenType, id: IdType) {
      return await callApi(`${DELETE_SUB_CATEGORIES}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    }
  },
  users: {
    async getAllUsers(token: TokenType) {
      return await callApi(USERS_GET, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async getOne(token: TokenType, id: IdType) {
      return await callApi(`${USERS_GET}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async create(token: TokenType, body: BodyType) {
      return await callApi(USER_CREATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async updateUser(token: TokenType, id: IdType, body: BodyType) {
      return await callApi(`${USER_UPDATE}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body,
        redirect: 'follow'
      });
    },
    async deleteUser(token: TokenType, id: IdType) {
      return await callApi(`${USER_DELETE}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    }
  },
  profile: {
    async getProfile(token: TokenType, id: IdType) {
      return await callApi(`${GET_PROFILE}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      });
    },
    async updateProfile(token: TokenType, id: IdType, formData: Record<string, unknown>) {
      return await callApi(`${PROFILE_UPDATE}/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData,
        redirect: 'follow'
      });
    }
  }
};

export default api;
