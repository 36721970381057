import React, { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';
// import { useUpdateProfile } from '../../hooks/useProfile';
import AccountBasicInfoForm from '../../components/Settings/AccountBasicInfoForm';
// import AccountSecurityForm from '../../components/Settings/AccountSecurityForm';

const Account = (): JSX.Element => {
  const { theme } = useContext(ThemeContext);
  // const { updatedProfile, updateProfile } = useUpdateProfile();
  return (
    <>
      <section className={`account ${theme}`}>
        <div className='account__header'>
          <h2>Editar perfil</h2>
        </div>

        <AccountBasicInfoForm theme={theme} />
        {/* Se comenta porque no es necesario que en los settings pueda modificarse la contraseña ya que el acceso es por el mail de microsoft (...@portinos.com.ar) */}
        {/* <AccountSecurityForm
          theme={theme}
          updateProfile={updateProfile}
          updatedProfile={updatedProfile}
        /> */}
      </section>
    </>
  );
};

export default Account;
