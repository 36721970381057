/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
// Librerias
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-cool-img';
import PropTypes from 'prop-types';

// Contextos

// Hooks
import useOffers from '../../hooks/useOffers';
// Componentes

// Imagenes
import iconByArea from '../../helpers/IconByArea';
import LoaderImage from '../../assets/images/static/loader.gif';
import ErrorImage from '../../assets/images/icons/general__icon.png';

// Estilos

interface Props {
  categorie: Record<string, number>;
}
interface Offer {
  id: number;
  title: string;
  short_description: string;
  categories: Category[];
}
interface Category {
  name: string;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const RelatedOffers = ({ categorie }: Props): JSX.Element => {
  // Estados
  const [items, setItems] = useState<Offer[]>([]);

  // Contextos

  // Hooks
  const { data, isLoading, isSuccess, isError, setFilters, filters } = useOffers();

  // Funciones
  const shuffle = (
    array: Array<Record<number, number>>
  ): Array<Record<number, number>> => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // mientras queden elementos para desordenar
    while (currentIndex !== 0) {
      // selecciona un elemento aleatorio
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // intercambia el elemento actual con el elemento aleatorio
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  // UseEffects
  useEffect(() => {
    if (data) {
      if (data.data) {
        let items = data.data.slice(); // crea una copia del array original
        if (items.length >= 2) {
          // desordena el array aleatoriamente
          items = shuffle(items);
          // selecciona los primeros dos elementos
          items = items.slice(0, 2);
        }
        setItems(items);
      }
    }
  }, [data]);

  useEffect(() => {
    setFilters({
      ...filters,
      active: null,
      expired: null,
      categories: categorie,
    });
  }, []);

  // Renders
  return (
    <>
      <div className='details__content'>
        <h3>Ofertas relacionadas:</h3>

        <div className='details__content__column'>
          {isLoading && (
            <Link to='/offers/all/' className='details__content__info'>
              <Img src={LoaderImage} alt='Job icon' />
              <div>
                <p className='title'>Cargando...</p>
                <span className='text'>Estamos escogiendo las mejores ofertas.</span>
              </div>
            </Link>
          )}
          {isSuccess && (
            <>
              {items.map((item: Offer) => (
                <Link
                  key={item.id}
                  to={`/offers/all/details/${item.id}`}
                  className='details__content__info'
                >
                  <Img
                    placeholder={LoaderImage}
                    src={iconByArea(item.categories[0].name)}
                    error={ErrorImage}
                    alt='Job icon'
                  />
                  <div>
                    <p className='title'>{item.title}</p>
                    <span className='text'>
                      {item.short_description.slice(0, 120)}...
                    </span>
                  </div>
                </Link>
              ))}
            </>
          )}
          {isError && (
            <Link to='/offers/all/' className='details__content__info'>
              <Img src={ErrorImage} alt='Job icon' />
              <div>
                <p className='title'>Algo ha ocurrido</p>
                <span className='text'>No hemos podido rescatar la informacion.</span>
              </div>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

RelatedOffers.propTypes = {
  categorie: PropTypes.number.isRequired,
};

RelatedOffers.defaultProps = {};

export default RelatedOffers;
