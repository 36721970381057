// Librerias
import { type SetStateAction } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';

// Contextos

// Hooks

// Componentes

// Imagenes

// Estilos

// Tipado
interface Props {
  firtsColumn: number;
  data: {
    data: Category[];
  };
  handleFather: (slug: SetStateAction<number>) => void;
}
interface Category {
  id: number;
  name: string;
}

/**
 * Descripción: Representa graficamente la primera columna de la tabla
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const ParentCategories = ({ firtsColumn, handleFather, data }: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <>
      <div className='categories__column'>
        <div className='categories__column__header'>
          <h2>Categorías padres:</h2>
        </div>

        <ul>
          {data?.data?.map((item: Category) => (
            <li key={item.id} className={firtsColumn === item.id ? 'active' : ''}>
              <button
                onClick={() => {
                  handleFather(item.id);
                }}
              >
                {item.name} <MdArrowForwardIos />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ParentCategories;
