/* eslint-disable @typescript-eslint/no-unused-vars */

// Librerias
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseMutationResult
} from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

// Types

// Otros
import api from '../services/api.services';
import { KEY_LOGIN } from '../constants/auth.constant';

/**
 * Descripcion del hook: Aqui hacemos los llamados y request a la api para el perfil del usuario
 *
 * Implementacion: Descripción sobre cómo puedes implementar el hook.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

interface LoginResponse {
  token: string;
  id: string;
}

interface EmailAndPassword {
  email: string;
  password: string;
}

interface AuthResponseInterface {
  loginResponse: UseMutationResult<LoginResponse, unknown, EmailAndPassword, unknown>;
  handleLogin: (email: string, password: string) => Promise<void>;
}

interface LogOutInterface {
  handleLogOut: () => void;
}

export const useLogOut = (): LogOutInterface => {
  const navigate = useNavigate();

  const handleLogOut = (): void => {
    navigate('/login', { replace: true });
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
  };

  return { handleLogOut };
};

// export const useChangePassword = () => {
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   const changePasswordResponse = useMutation({
//     mutationFn: async ({ newPass }) => {
//       return api.login.changePass(newPass);
//     },

//     onSuccess: (data) => {
//       queryClient.invalidateQueries([KEY_LOGIN]);

//       toast.dismiss();
//       toast.success(data.message);

//       navigate('/login', { replace: true });
//     },

//     onError: (data) => {
//       toast.dismiss();
//       toast.error(data.message || 'Algo ha ocurrido');
//     },
//   });

//   const sendNewPassword = async (newPass) => changePasswordResponse.mutate({ newPass });

//   return { changePasswordResponse, sendNewPassword };
// };

// export const useRecoveryPassword = () => {
//   const queryClient = useQueryClient();

//   const recoveryResponse = useMutation({
//     mutationFn: async ({ email }) => {
//       return api.login.recovery(email);
//     },

//     onSuccess: (data) => {
//       queryClient.invalidateQueries([KEY_LOGIN]);

//       toast.dismiss();
//       toast.success(data.message);
//     },

//     onError: (data) => {
//       toast.dismiss();
//       toast.error(data.message || 'Algo ha ocurrido');
//     },
//   });

//   const sendRecoveryToken = async (email) => recoveryResponse.mutate({ email });

//   return { recoveryResponse, sendRecoveryToken };
// };

const useAuth = (): AuthResponseInterface => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const loginResponse = useMutation<LoginResponse, unknown, EmailAndPassword>({
    mutationFn: async ({ email, password }: EmailAndPassword) => {
      return await api.login.singIn(JSON.stringify({ email, password }));
    },

    onSuccess: (data: LoginResponse) => {
      void queryClient.invalidateQueries([KEY_LOGIN]);
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('userId', data.id);
      navigate('/offers/all', { replace: true });
    },

    onError: (error: unknown) => {
      toast.dismiss();
      toast.error((error as Error).message);
      sessionStorage.clear();
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 1000);
    }
  });

  const handleLogin = async (email: string, password: string): Promise<void> => {
    loginResponse.mutate({ email, password });
  };

  return { loginResponse, handleLogin };
};

export default useAuth;
