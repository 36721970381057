/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, {
  useEffect,
  useState,
  useContext,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { toast } from 'react-hot-toast';

// Contextos
import ThemeContext from '../../../context/ThemeContext';

// Hooks
import useModal from '../../../hooks/useModal';
import { useDeleteSubCategorie } from '../../../hooks/useSubCategories';

// Componentes
import SubCategoriesTable from './SubCategoriesTable';
import SubCategoriesModal from './SubCategoriesModal';
import UpdateSubCategoriesModal from './UpdateSubCategoriesModal';
import ModalDelete from '../../Modals/ModalDelete';

// Imagenes

// Estilos

// Types
interface Props {
  childs: [];
  secondColumn: number | null;
  setShowOption: Dispatch<SetStateAction<boolean>>;
  handleUpdate: () => void;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const SubCategories = ({
  childs,
  setShowOption,
  secondColumn,
  handleUpdate,
}: Props): JSX.Element => {
  // Estados
  const [OptionID, setOptionID] = useState<null>(null);
  const [create, setCreate] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  // Contextos
  const { theme } = useContext(ThemeContext);

  // Hooks
  const [isShowingModal, toggleModal] = useModal();
  const { handleDelete, deleteSubCategories } = useDeleteSubCategorie();

  // Funciones
  const handleModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: string,
    id: null
  ): void => {
    e.stopPropagation();

    toggleModal();
    setOptionID(id);

    if (type === 'edit') {
      setEdit(true);
      setDeleteModal(false);
      setCreate(false);
    }

    if (type === 'create') {
      setCreate(true);
      setEdit(false);
      setDeleteModal(false);
      setOptionID(null);
    }

    if (type === 'delete') {
      setDeleteModal(true);
      setEdit(false);
      setCreate(false);
    }
  };

  // UseEffects

  // Esta función se ejecuta cada vez que se actualiza el estado de offers.  Utilizamos useEffect para realizar una serie de filtros sobre los datos de offers.
  useEffect(() => {
    const hasAnUpdate: string | null = sessionStorage.getItem('updateCategories');

    if (hasAnUpdate === 'true') {
      handleUpdate();
      sessionStorage.setItem('updateCategories', 'false');
    }

    setShowOption(false);
  }, [isShowingModal]);

  useEffect(() => {
    if (deleteSubCategories.isLoading === true) {
      toast.loading('Enviando...');
    }

    if (deleteSubCategories.data) {
      deleteSubCategories.reset();
      toggleModal();
      setOptionID(null);
    }
  }, [deleteSubCategories]);

  // Renders
  return (
    <>
      {create ? (
        <SubCategoriesModal
          theme={theme}
          handleClick={toggleModal}
          secondColumn={secondColumn}
          isShowingModal={isShowingModal}
        />
      ) : null}

      {edit ? (
        <UpdateSubCategoriesModal
          theme={theme}
          OptionID={OptionID}
          handleClick={toggleModal}
          isShowingModal={isShowingModal}
        />
      ) : null}

      {deleteModal ? (
        <ModalDelete
          toggleModal={toggleModal}
          setCleanData={() => null}
          isShowingModal={isShowingModal}
          handleAction={async () => {
            await handleDelete(OptionID);
            sessionStorage.setItem('updateCategories', 'true');
          }}
          isLoading={deleteSubCategories.isLoading}
        />
      ) : null}

      <div className='categories__column last'>
        <div className='categories__column__header'>
          <h2>Subcategorías:</h2>
          <button
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              handleModal(e, 'create', null);
            }}
          >
            Crear nueva
          </button>
        </div>

        <SubCategoriesTable childs={childs} handleModal={handleModal} />
      </div>
    </>
  );
};

export default SubCategories;
