/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import React, {
  useRef,
  type Dispatch,
  type SetStateAction,
  // type MutableRefObject,
} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { type Editor as TinyMCEEditor } from 'tinymce';

// Contextos

// Hooks

// Componentes
import { API_KEY } from '../constants/markdown.constant';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../types/ThemeContext';

interface Props {
  theme: ThemeContextType['theme'];
  contentUpdate?: (content: string) => void;
  contentData?: string | undefined | null;
  prevContent: string;
  setContentData: Dispatch<SetStateAction<null | string | undefined>>;
}

/**
 * Descripción: Este componente se encarga de crear texto enriquesido para el formulario de ofertas
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: No esta enviando los emojis como deberia
 */

const Markdown = ({ theme, prevContent, setContentData }: Props): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const editorRef = useRef<TinyMCEEditor | null>(null);

  // Funciones
  const handleChange = (): void => {
    if (editorRef.current != null) {
      setContentData(editorRef.current.getContent());
    }
  };

  const plugins = [
    'autolink',
    'lists',
    'link',
    'preview',
    'searchreplace',
    'fullscreen',
    'help',
    'wordcount',
  ];

  const toolbar =
    'undo redo | bold italic underline strikethrough blocks | forecolor backcolor removeformat | numlist bullist  | emoticons link codesample preview';

  // UseEffects

  // Renders
  return (
    <>
      <Editor
        // selector='textarea' // change this value according to your HTML
        apiKey={API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={handleChange}
        initialValue={
          prevContent.length > 0 ? prevContent : '<p>Contenido de la oferta.</p>'
        }
        init={{
          language: 'es_MX',
          language_url: '/languages/es_MX.js',
          skin: theme === 'dark' ? 'oxide-dark' : 'oxide',
          content_css: theme === 'dark' ? 'dark' : 'default',
          height: 700,
          statusbar: true,
          plugins,
          toolbar,
          menubar: false,
          toolbar_sticky: true,
          content_style: 'body { font-family:Poppins,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  );
};

export default Markdown;
