// Librerias
import React from 'react';
import { Route, Routes as BrowserRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// Contextos

// Hooks

// Componentes
import RequireAuth from '../helpers/RequireAuth';
import Layout from '../layout/Layout';
import Login from '../pages/Login/Login';
// import PasswordRecovery from '../pages/Login/PasswordRecovery';
// import ChangePassword from '../pages/Login/ChangePassword';
import Offers from '../pages/Offers/Offers';
import OfferDetails from '../pages/Offers/OffersDetails';
import Applicants from '../pages/Applicants/Applicants';
import Applicant from '../components/Applicants/Applicant';
import Categories from '../pages/Categories/Categories';
import Settings from '../pages/Settings/Settings';
import ErrorPage from '../pages/Errors/ErrorPage';
import Privacy from '../pages/Privacy/Privacy';
import Blank from '../components/Login/Blank';

// Imagenes

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Routes = (): JSX.Element => {
  // Estados

  // Contextos

  // Hooks

  // Funciones

  // UseEffects

  // Renders
  return (
    <BrowserRoutes>
      <Route
        path='/'
        element={
          <RequireAuth>
            <AnimatePresence mode='wait' initial={false}>
              <Layout />
            </AnimatePresence>
          </RequireAuth>
        }
      >
        <Route path='offers/:state/details/:Id' element={<OfferDetails />} />
        <Route path='offers' element={<Offers />}>
          <Route path=':state' element={<Offers />} />
        </Route>
        <Route path='categories' element={<Categories />} />

        <Route path='applicants' element={<Applicants />}>
          <Route path=':applicantId' element={<Applicant />} />
        </Route>

        <Route path='settings' element={<Settings />}>
          <Route path=':tab' element={<Settings />} />
        </Route>

        <Route path='*' element={<ErrorPage />} />
      </Route>

      <Route path='/login' element={<Login />} />
      {/* <Route path='/login/recovery-password' element={<PasswordRecovery />} /> */}
      {/* <Route path='/login/change-password' element={<ChangePassword />} /> */}
      <Route path='/blank' element={<Blank />} />
      <Route path='/privacidad' element={<Privacy />} />
    </BrowserRoutes>
  );
};

export default Routes;
