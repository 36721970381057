/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/forbid-prop-types */

// Librerias
import React, {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import PropTypes from 'prop-types';

// Contextos
import ThemeContext from '../../context/ThemeContext';
import SidebarContext from '../../context/SidebarContext';

// Hooks
import { useDeleteComment } from '../../hooks/useComments';

// Componentes
import formatDate from '../../helpers/FormatDate';
import { type IdType } from '../../types/ReactQuery';

// Imagenes

// Estilos

interface Props {
  refetch: () => void;
  comments: string;
  isLoading?: boolean;
  handleSubmit: (e: { preventDefault: () => void }) => void;
  session: Record<string, unknown>;
  // data: Array<Record<string, unknown>>;
  data: string[];
  setComments: Dispatch<SetStateAction<string>>;
}
interface User {
  id: IdType;
  avatar?: string;
  firtsName?: string;
  lastName?: string;
  user: User;
}

interface Comment {
  id: IdType;
  user: User;
  comment: string;
  createdAt: string;
}

/**
 * Descripción:
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Comments = ({
  data,
  refetch,
  session,
  comments,
  setComments,
  handleSubmit,
}: Props): JSX.Element => {
  // Contextos
  const { theme } = useContext(ThemeContext);
  const { toggleSide, setUser } = useContext(SidebarContext);

  // hooks
  const { handleDelete, deleteComment } = useDeleteComment();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  // Funciones
  const handleProfile = (id: IdType): void => {
    toggleSide(true);
    setUser(id);
  };

  const renderCommentItem = (comment: Comment): JSX.Element | undefined => {
    if (!comment.user) {
      return;
    }

    return (
      <div key={comment.id} className='comments__comment'>
        <img
          src={comment.user.avatar}
          alt='Imagen de perfil'
          onClick={() => {
            handleProfile(comment.user.id);
          }}
        />
        <div className='comments__comment__body'>
          <p
            onClick={() => {
              handleProfile(comment.user.id);
            }}
          >
            {`${comment.user.firtsName} ${comment.user.lastName}`}
          </p>
          <span>{comment.comment}</span>
        </div>

        <div className='comments__comment__footer'>
          <small>{formatDate(comment.createdAt)}</small>

          <div>
            {/* <button>Editar</button> */}
            {comment.user.id === session?.id && (
              <button onClick={async () => handleDelete(comment.id)}>Eliminar</button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const scrollToBottomScrollbarComments = (): void => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // useEffect
  useEffect(() => {
    if (deleteComment.data) {
      deleteComment.reset();
      refetch();
    }
  }, [deleteComment]);

  useEffect(() => {
    scrollToBottomScrollbarComments();
  });

  return (
    <div className={`comments ${theme}`}>
      <h3>Comentarios: </h3>

      <div className='comments__container'>
        {data?.length ? (
          <>
            {data
              // .sort((a: any, b: any) => b.id - a.id) // //  → se comenta porque por defecto ordena de más viejo a más nuevo (más nuevo abajo)
              .map((comment) => renderCommentItem(comment as unknown as Comment))}
            <div ref={messagesEndRef} />
            {/* ↑ messagesEndRef sirve para llevar el scrollbar de los comentarios al final ↑ */}
          </>
        ) : (
          <p className='empty'>No hay comentarios</p>
        )}

        <form onSubmit={handleSubmit} className='comments__form'>
          <input
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
            type='text'
            placeholder='Escribe un comentario'
          />
          <button type='submit'>
            <AiOutlineSend />
          </button>
        </form>
      </div>
    </div>
  );
};

// Esta parte define los tipos de datos que se esperan recibir como props en el componente Comments.
Comments.propTypes = {
  // Propiedad session, un objeto
  session: PropTypes.object,
  // Propiedad data, un arreglo de datos
  data: PropTypes.array,
  // Propiedad handleSubmit, una función
  handleSubmit: PropTypes.func,
  // Propiedad comments, un string
  comments: PropTypes.string,
  // Propiedad setComments, una función
  setComments: PropTypes.func,
  // Propiedad refetch, una función
  refetch: PropTypes.func,
};

// Esta parte define los valores por defecto para las propiedades del componente Comments.
Comments.defaultProps = {
  // Valor por defecto para la propiedad session es un objeto vacío {}
  session: {},
  // Valor por defecto para la propiedad data es un arreglo vacío []
  data: [],
  // Valor por defecto para la propiedad handleSubmit es null
  handleSubmit: () => null,
  // Valor por defecto para la propiedad comments es un string vacío ''
  comments: '',
  // Valor por defecto para la propiedad setComments es null     setComments: () => null,     refetch: () => null    };
  setComments: () => null,
  refetch: () => null,
};

export default Comments;
