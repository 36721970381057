/* eslint-disable @typescript-eslint/no-unused-vars */
// Librerias
import React, { useContext, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// Contextos
import ThemeContext from '../context/ThemeContext';
import SidebarContext from '../context/SidebarContext';

// Hooks
import useProfile from '../hooks/useProfile';
import { useLogOut } from '../hooks/useAuth';

// Componentes
import Header from './Header/Header';
import Aside from './Aside/Aside';
import Sidebar from './Sidebar/Sidebar';

// Imagenes

// Estilos

/**
 * Descripción: El Layout envuelve la aplicacion en general, este parte siempre persiste.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Layout = (): JSX.Element => {
  // Estados
  const [open, setOpen] = useState<boolean>(true);

  // Contextos
  const { theme, toggleTheme } = useContext(ThemeContext);

  const { sideOpen, setUser, userId, toggleSide, userAvatarImg, setUserAvatarImg, firstName, setFirstName, lastName, setLastName} = useContext(SidebarContext);

  // Hooks
  const userLoggedIn: string | null = sessionStorage.getItem('userId');
  const { data, isError, isLoading, isSuccess } = useProfile(userLoggedIn);
  const { handleLogOut } = useLogOut();

  // Funciones

  // UseEffects
  useEffect(() => {
    return () => {
      toggleSide(false);
      setUser(null);
    };
  }, []);

  useEffect(() => {
    setUserAvatarImg(data?.avatar);
    setFirstName(data?.firtsName);
    setLastName(data?.lastName);
  }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          transition: 'all 0.5s ease-in-out',
          minHeight: '100%'
        }}
      >
        <Aside theme={theme} open={open} setOpen={setOpen} />
        <div
          style={{
            marginLeft: open ? '260px' : '60px',
            transition: 'all 0.3s ease-in-out',
            width: '100%'
          }}
        >
          
          <Header
            theme={theme}
            open={open}
            user={data}
            setUser={setUser}
            isError={isError}
            sideOpen={sideOpen}
            isLoading={isLoading}
            isSuccess={isSuccess}
            toggleSide={toggleSide}
            toggleTheme={toggleTheme}
            userAvatarImg={userAvatarImg}
            setUserAvatarImg={setUserAvatarImg}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
          />

          <Sidebar
            theme={theme}
            userId={userId}
            setUser={setUser}
            sideOpen={sideOpen}
            logOut={handleLogOut}
            toggleSide={toggleSide}
            userLoggedIn={userLoggedIn}
            userAvatarImg={userAvatarImg}
            setUserAvatarImg={setUserAvatarImg}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
          />
          <div
            style={{
              marginRight: sideOpen ? '310px' : '0px',
              transition: 'all 0.3s ease-in-out'
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
