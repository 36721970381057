/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// Contextos

// Hooks
import useAuth from '../../hooks/useAuth';

// Componentes

// Imagenes

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Blank = (): JSX.Element => {
  // Estados

  // Contextos

  // Hooks
  const { loginResponse, handleLogin } = useAuth();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || null);
  const navigate = useNavigate();

  // Funciones

  // UseEffects
  useEffect(() => {
    const getToken = async (): Promise<void> => {
      if (account != null && instance) {
        if (Object.keys(account).length > 0) {
          try {
            const tokenResponse = await instance.acquireTokenSilent({
              scopes: ['profile'],
              account,
            });

            if (tokenResponse) {
              void handleLogin(account.username, tokenResponse.accessToken);
            }
          } catch (error: any) {
            // ↑ type annotation must be 'any'
            console.log(error);
            toast.dismiss();
            if (typeof error === 'object') {
              toast.error(error.message);
            }
            sessionStorage.clear();
            setTimeout(() => {
              navigate('/login', { replace: true });
            }, 1000);
          }
        }
      }
    };

    void getToken();
    // agregar las dependencias faltantes
  }, [account, instance]);

  useEffect(() => {
    return () => {
      if (loginResponse.data != null) {
        loginResponse.reset();
      }
    };
  }, [loginResponse]);

  // Renders
  return (
    <>
      <p>Verificando...</p>
    </>
  );
};

Blank.propTypes = {};

Blank.defaultProps = {};

export default Blank;
