/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { motion } from 'framer-motion';
import { type Filters } from '../../types/ReactQuery';

// Contextos

// Hooks

// Componentes
import FiltersSelects from '../Forms/FiltersSelects';
import Paginate from '../Paginate';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  theme: ThemeContextType['theme'];
  setFilters: (filters: Filters) => void;
  offers: OfferData;
  filters: Filters;
  currentItems: Applicant[];
  setCurrentItems: Dispatch<SetStateAction<Applicant[]>>;
}
interface Applicant {
  id: number;
  active: boolean;
  names: string;
  last_names: string;
  email: string;
}

// interface FilterState {
//   page: number;
//   size: number;
//   order: string;
//   sort: string;
// }
interface OfferData {
  data: Applicant[];
  info: {
    total_count: number;
  };
}

/**
 * Descripción: Footer que envuelve ciertos filtros
 *
 * Implementación: Componente creado especialmente para esta necesidad
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const OffersFooter = ({
  theme,
  offers,
  filters,
  setFilters,
  currentItems,
  setCurrentItems,
}: Props): JSX.Element => {
  // Estados
  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(filters.page ? filters.page - 1 : 0);

  // Contextos

  // Hooks

  // Funciones
  const { data } = offers;

  const sizeOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const orderOptions = [
    { value: 'title', label: 'Alfabéticamente' },
    { value: 'updated-at', label: 'Por actualización' },
    { value: 'created-at', label: 'Por creación' },
    { value: 'end-date', label: 'Por vencimiento' },
  ];

  const sortOptions = [
    { value: 'asc', label: '↑' },
    { value: 'desc', label: '↓' },
  ];

  const handlePageChange = (selectedObject: { selected: number }): void => {
    setCurrentPage(selectedObject.selected);
    setFilters({
      ...filters,
      page: selectedObject.selected + 1,
    });
  };

  const handleSize = (value: { value: number }): void => {
    setFilters({
      ...filters,
      size: value.value,
    });
  };

  const handleOrder = (value: { value: string }): void => {
    setFilters({
      ...filters,
      order: value.value,
    });
  };

  const handleSort = (value: { value: string }): void => {
    setFilters({
      ...filters,
      sort: value.value,
    });
  };

  // UseEffects
  useEffect(() => {
    setCurrentPage(filters.page ? filters.page - 1 : 0);
  }, [filters]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(offers?.info?.total_count / filters.size));
  }, [data, filters]);

  // Renders
  return (
    <motion.div transition={{ duration: 0.2 }} className={`pagination ${theme}`}>
      <article>
        <span>Cantidad:</span>
        <FiltersSelects
          name='size'
          menuPlacement='top'
          onChange={handleSize}
          options={sizeOptions}
          defaultValue={sizeOptions.find((item) => item.value === filters.size)}
        />
      </article>

      <Paginate
        info={offers?.info}
        pageCount={pageCount}
        currentPage={currentPage}
        currentItems={currentItems}
        handlePageChange={handlePageChange}
        itemsPerPage={filters.size}
      />

      <div
        style={{
          width: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <article>
          <span>Ordenar:</span>
          <FiltersSelects
            name='order'
            menuPlacement='top'
            onChange={handleOrder}
            options={orderOptions}
            defaultValue={orderOptions.find((item) => item.value === filters.order)}
          />
        </article>

        <article>
          <span>Sortear:</span>
          <FiltersSelects
            name='order'
            menuPlacement='top'
            onChange={handleSort}
            options={sortOptions}
            defaultValue={sortOptions.find((item) => item.value === filters.sort)}
          />
        </article>
      </div>
    </motion.div>
  );
};

export default OffersFooter;
