// Otros

/**
 * Descripcion del helper: este helper se encarga de generar una imagen mediante texto
 *
 * Implementacion: Se importa en el SRC de la imagen y automaticamente devuelve una url.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

// Esta función crea una imagen a partir de un string dado.
const ImgGenerator = (str: string = 'Juan Jose Balvin'): string => {
  // Se separa el string en palabras y se almacenan en un arreglo.
  const stringToArray = str.split(' ');

  // Se obtiene la primera letra de la primera palabra del arreglo.
  const firtsWord = stringToArray[0].split('')[0].toLocaleUpperCase();

  // Si hay 4 o más palabras, se obtiene la tercera letra de la tercera palabra,
  // si no, se obtiene la última letra de la última palabra del arreglo.
  const lastWord =
    stringToArray.length >= 4
      ? stringToArray[2].split('')[0]
      : stringToArray[stringToArray.length - 1].split('')[0];

  // Se concatenan las dos primeras letras para formar un nuevo string.
  const letters = firtsWord + lastWord;

  // Se crea un canvas para dibujar los caracteres del nuevo string.
  const canvas: HTMLCanvasElement = document.createElement('canvas');

  // Se obtiene el contexto para poder dibujar en el canvas.
  const ctxCanvas: CanvasRenderingContext2D | null = canvas.getContext('2d');

  // Se establece el ancho del canvas como el ancho del texto a dibujar (opcional).
  //   ctxCanvas.canvas.width = ctxCanvas.measureText(letters).width;

  // El canvas no se muestra en pantalla, sino que es utilizado para generar una imagen con los caracteres dados como parámetro a la función ImgGenerator().
  canvas.style.display = 'none';

  // Se establece el ancho y alto del canvas como 200px por 200px respectivamente (opcional).
  canvas.width = 200;
  canvas.height = 200;

  if (ctxCanvas !== null) {
    ctxCanvas.textAlign = 'center';
    ctxCanvas.font = 'bold 90px Poppins';
    ctxCanvas.fillStyle = '#FFFFFFFF';
  }

  ctxCanvas?.fillText(letters, canvas.width / 2, 130);

  return ctxCanvas?.canvas.toDataURL() ?? '';
};

export default ImgGenerator;
