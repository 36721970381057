/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-await */
/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import { useMutation, useQuery, useQueryClient, type UseQueryResult } from 'react-query';
import toast from 'react-hot-toast';

// Otros
import api from '../services/api.services';
import { KEY_PARENT_CATEGORIES, KEY_CATEGORIES } from '../constants/categories.constant';
import {
  type UpdateCategorie,
  type CategorieInterface,
  type DeleteCategorieInterface,
  type CreateCategorieInterface
} from './useCategories.types';
import { type TokenType, type BodyType, type IdType } from '../types/ReactQuery';


// Esta función se encarga de crear una oferta
export const useCreateCategorie = (): CreateCategorieInterface => {
  const token: string | null = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const newCategorie = useMutation({
    mutationFn: async (body: BodyType) => await api.categories.create(token, body),
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_CATEGORIES]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Oferta creada correctamente! ❤️');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  const createCategories = async (body: BodyType): Promise<void> => {
    newCategorie.mutate(body);
  };

  return { newCategorie, createCategories };
};

// Esta función se encarga de eliminar una oferta
export const useDeleteCategorie = (): DeleteCategorieInterface => {
  const token: TokenType = sessionStorage.getItem('token');

  // Se obtiene la sesión del usuario desde el contexto de autenticación

  // Se obtiene el cliente de consultas para realizar refetchs
  const queryClient = useQueryClient();

  // Se crea una mutación para eliminar la oferta
  const deleteCategories = useMutation({
    mutationFn: async (id: IdType) => await api.categories.delete(token, id),
    onSuccess: (data) => {
      // Cuando la operación es exitosa, se realiza un refetch a las Categorias
      void queryClient.invalidateQueries([KEY_CATEGORIES]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Eliminado correctamente!');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  // Esta función se encarga de ejecutar la mutación para eliminar una oferta en particular
  const handleDelete = async (id: IdType): Promise<void> => {
    deleteCategories.mutate(id);
  };

  return { handleDelete, deleteCategories };
};

// Esta función se encarga de actualizar una oferta
export const useUpdateCategorie = (): UpdateCategorie => {
  const token: TokenType = sessionStorage.getItem('token');

  // Se obtiene la sesión del contexto de autenticación

  // Se obtiene el cliente de consultas
  const queryClient = useQueryClient();

  // Se crea una mutación para actualizar la oferta
  const updatedCategorie = useMutation({
    mutationFn: async ({ id, body }: { id: IdType; body: BodyType }) => {
      // Se realiza la petición al servidor para actualizar la oferta con los datos proporcionados
      return await api.categories.update(token, id, body);
    },

    // Si la petición es exitosa se invalida las consultas relacionadas con las Categorias y se muestra un mensaje de éxito o error dependiendo del resultado obtenido del servidor
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_CATEGORIES]);

      if (data.error) {
        toast.dismiss();
        toast.error(data.message);
      } else {
        toast.dismiss();
        toast.success(data.message);
      }
    },

    // Si ocurre algún error en la petición se muestra un mensaje de error al usuario informando del problema ocurrido
    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  // Esta función recibe el identificador y los datos a actualizar de la oferta y ejecuta la mutación creada anteriormente para realizar la petición al servidor
  const updateCategorie = async (id: IdType, body: BodyType): Promise<void> => {
    updatedCategorie.mutate({ id, body });
  };

  return { updatedCategorie, updateCategorie };
};

// Esta función permite obtener los datos de una oferta específica.
export const useCategorie = (id: IdType): CategorieInterface => {
  // Se obtiene el contexto de autenticación.
  const token: TokenType = sessionStorage.getItem('token');

  // Se realiza la consulta a la API para obtener los datos de la oferta.
  const { data, isLoading, error, refetch } = useQuery(
    [KEY_CATEGORIES, id],
    async () => await api.categories.getOneCategorie(token, id)
  );

  // Se devuelven los datos obtenidos de la consulta a la API.
  return { data, isLoading, error, refetch };
};

// Trae todas las Categorias
export const useCategories = (): UseQueryResult<any, unknown> => {
  const token: TokenType = sessionStorage.getItem('token');
  

  const categories = useQuery(
    [KEY_CATEGORIES],
    async () => await api.categories.getAllCategories(token)
  );
  return categories;
};

export const useParentCategories = (): UseQueryResult<any, unknown> => {
  const token: TokenType = sessionStorage.getItem('token');

  const parentCategories = useQuery(
    [KEY_PARENT_CATEGORIES],
    async () => await api.parentCategories.getAllCategories(token)
  );

  return parentCategories;
};
