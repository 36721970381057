export const MICROSOFT_LOGIN = process.env.REACT_APP_MICROSOFT_LOGIN ?? '';
export const MICROSOFT_LOGOUT = process.env.REACT_APP_MICROSOFT_LOGOUT ?? '';
export const MICROSOFT_SPA_CODE = process.env.REACT_APP_MICROSOFT_SPA_CODE ?? '';

export const KEY_LOGIN = 'LOGIN';
export const AUTH_LOGIN = process.env.REACT_APP_AUTH_LOGIN ?? '';
export const AUTH_RECOVERY = process.env.REACT_APP_AUTH_RECOVERY ?? '';
export const AUTH_CHANGE_PASSWORD = process.env.REACT_APP_AUTH_CHANGE_PASSWORD ?? '';

export const KEY_USERS = 'users';
export const KEY_USER_UPDATE = 'newuser';
export const USERS_GET = process.env.REACT_APP_USERS ?? '';
export const USER_CREATE = process.env.REACT_APP_USERS ?? '';
export const USER_UPDATE = process.env.REACT_APP_USERS ?? '';
export const USER_DELETE = process.env.REACT_APP_USERS ?? '';

export const KEY_PROFILES = 'profiles';
export const GET_PROFILE = process.env.REACT_APP_PROFILE ?? '';
export const PROFILE_UPDATE = process.env.REACT_APP_PROFILE ?? '';

export const MICROSOFT_TENANT_ID = process.env.REACT_APP_MICROSOFT_TENANT_ID ?? '';
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID ?? '';
export const MICROSOFT_CLIENT_SECRET =
  process.env.REACT_APP_MICROSOFT_CLIENT_SECRET ?? '';
export const MICROSOFT_REDIRECT_URI = process.env.REACT_APP_MICROSOFT_REDIRECT_URI ?? '';
