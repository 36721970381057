// Librerias
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MsalProvider } from '@azure/msal-react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { type IPublicClientApplication } from '@azure/msal-browser';
// Contextos
import { ThemeProvider } from '../context/ThemeContext';
import { SidebarProvider } from '../context/SidebarContext';

// Hooks

// Componentes
// import api from '../services/api.services';
import Routes from './Routes';

// Imagenes

// Estilos

/**
 * Descripción: Aqui se concentra todo las rutas y contextos de nuestra app.
 */

const App = ({ instance }: { instance: IPublicClientApplication }): JSX.Element => {
  // Estados

  // Funciones
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <SidebarProvider>
            <Routes />
          </SidebarProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </MsalProvider>
  );
};
export default App;
