/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

// Librerias
import React, {
  useEffect,
  useState,
  useContext,
  type MouseEvent,
  type SetStateAction,
  type Dispatch,
} from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import toast from 'react-hot-toast';

// Contextos
import ThemeContext from '../../../context/ThemeContext';

// Hooks
import useModal from '../../../hooks/useModal';
import { useDeleteCategorie } from '../../../hooks/useCategories';

// Componentes
import CategoriesModal from './CategoriesModal';
import UpdateCategoriesModal from './UpdateCategoriesModal';
import ModalDelete from '../../Modals/ModalDelete';
import Options from '../../Options';

// Imagenes

// Estilos

// Tipado
interface Props {
  parents: Category[];
  showOption: boolean;
  firtsColumn: number;
  secondColumn: number | null;
  setShowOption: Dispatch<SetStateAction<boolean>>;
  // handleSubCategorie: (
  //   e: { stopPropagation: () => void; target: { nodeName: string } },
  //   slug: SetStateAction<number | null>
  // ) => void;
  handleSubCategorie: (
    e: MouseEvent<HTMLButtonElement>,
    slug?: SetStateAction<number | null>,
    target?: { nodeName: string }
  ) => void;
  handleUpdate: () => void;
}
interface Category {
  id: string;
  name: string;
}

/**
 * Descripción: Este componente representa la segunda columna, donde se muestran las categorias
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const ChildrenCategories = ({
  parents,
  showOption,
  firtsColumn,
  secondColumn,
  setShowOption,
  handleSubCategorie,
  handleUpdate,
}: Props): JSX.Element => {
  // Estados
  const [OptionID, setOptionID] = useState<string | null>(null);
  const [createCategorie, setCreateCategorie] = useState<boolean>(false);
  const [editCategorie, setEditCategorie] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  // Contextos
  const { theme } = useContext(ThemeContext);
  const { handleDelete, deleteCategories } = useDeleteCategorie();

  // Hooks
  const [isShowingModal, toggleModal] = useModal();

  // Funciones
  const handleOptions = (e: MouseEvent, item: Category): void => {
    e.stopPropagation();

    if (item) {
      setOptionID(item.id);
      setShowOption(!showOption);
    }
  };

  const handleModal = (e: MouseEvent, type?: string): void => {
    e.stopPropagation();

    toggleModal();

    if (type === 'edit') {
      setEditCategorie(true);
      setDeleteModal(false);
      setCreateCategorie(false);
    }

    if (type === 'create') {
      setCreateCategorie(true);
      setEditCategorie(false);
      setDeleteModal(false);
      setOptionID(null);
    }

    if (type === 'delete') {
      setDeleteModal(true);
      setEditCategorie(false);
      setCreateCategorie(false);
    }
  };

  // UseEffects
  // Esta función se ejecuta cada vez que se actualiza el estado de offers.  Utilizamos useEffect para realizar una serie de filtros sobre los datos de offers.
  useEffect(() => {
    const hasAnUpdate: string | null = sessionStorage.getItem('updateCategories');

    if (hasAnUpdate === 'true') {
      handleUpdate();
      sessionStorage.setItem('updateCategories', 'false');
    }

    setShowOption(false);
  }, [isShowingModal]);

  useEffect(() => {
    if (deleteCategories.isLoading) {
      toast.loading('Enviando...');
    }

    if (deleteCategories.data) {
      deleteCategories.reset();
      toggleModal();
      setOptionID(null);
    }
  }, [deleteCategories]);

  // Renders
  return (
    <>
      {createCategorie ? (
        <CategoriesModal
          theme={theme}
          firtsColumn={firtsColumn}
          handleClick={toggleModal}
          isShowingModal={isShowingModal}
        />
      ) : null}

      {editCategorie ? (
        <UpdateCategoriesModal
          theme={theme}
          OptionID={OptionID}
          handleClick={toggleModal}
          isShowingModal={isShowingModal}
        />
      ) : null}

      {deleteModal ? (
        <ModalDelete
          toggleModal={toggleModal}
          setCleanData={() => null}
          isShowingModal={isShowingModal}
          isLoading={deleteCategories.isLoading}
          handleAction={async () => {
            await handleDelete(OptionID);
            sessionStorage.setItem('updateCategories', 'true');
          }}
        />
      ) : null}

      <div className='categories__column'>
        <div className='categories__column__header'>
          <h2>Categorías:</h2>
          <button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              handleModal(e, 'create');
            }}
          >
            Crear nueva
          </button>
        </div>

        <ul>
          {parents.map((item: Category) => (
            <li
              key={item.id}
              className={secondColumn === parseInt(item.id) ? 'active' : ''}
            >
              <button
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  handleSubCategorie(e, parseInt(item.id));
                }}
              >
                {item.name}{' '}
                {OptionID === item.id && (
                  <Options
                    id={OptionID}
                    showOption={showOption}
                    handleEdit={(e: MouseEvent<HTMLButtonElement>) => {
                      handleModal(e, 'edit');
                    }}
                    handleDelete={(e: MouseEvent<HTMLButtonElement>) => {
                      handleModal(e, 'delete');
                    }}
                  />
                )}
                <span
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    handleOptions(e, item);
                  }}
                >
                  <BiDotsVerticalRounded />
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ChildrenCategories;
