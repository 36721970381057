/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import {
  PublicClientApplication,
  EventType,
  type EventMessage,
  type AccountInfo,
} from '@azure/msal-browser';

import App from './routes/App';

import { msalConfig } from './config/msalConfig';

import './assets/scss/index.scss';

const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.payload) {
    if (event.eventType === EventType.LOGIN_SUCCESS && 'account' in event.payload) {
      const { account } = event.payload as { account: AccountInfo };
      msalInstance.setActiveAccount(account);
    }
  }
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Toaster position='top-right' />
      <App instance={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
