// Librerias
import React, { type Dispatch, type SetStateAction } from 'react';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion';

// Contextos
import ThemeContext from '../../context/ThemeContext';
// Hooks
import useApplicants from '../../hooks/useApplicants';

// Componentes
import Filters from './Filters';
import Paginate from '../Paginate';
import ApplicantsItems from './ApplicantsItems';
import StatusLoading from '../../assets/animations/lottie-loading.json';
import Anim404 from '../../assets/animations/404.json';

import FiltersSelects from '../Forms/FiltersSelects';

// Imagenes

// Estilos

// Types
export interface Props {
  setApplicantId: Dispatch<SetStateAction<string | number | null>>;
  applicantId: string | number | null;
}

interface Info {
  count: number;
  page: number;
  size: number;
  status: number;
  total_count: number;
  total_pages: number;
}
interface Applicant {
  id: number;
  active: boolean;
  names: string;
  last_names: string;
  email: string;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const AplicantsList = ({ setApplicantId, applicantId }: Props): JSX.Element => {
  // Contextos
  const { theme } = React.useContext(ThemeContext);

  // Custom Hooks
  const { data, isLoading, isError, filters, setFilters, setPage, page, isSuccess } =
    useApplicants();

  // Funciones
  const sizeOptions: Array<{ value: number; label: string }> = [
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 60,
      label: '60',
    },
  ];

  const orderOptions: Array<{ value: string; label: string }> = [
    { value: 'updated-at', label: 'Actualizadas primero' },
    { value: 'created-at', label: 'Más recientes primero' },
  ];

  const info: Info = data?.info;
  const applicants: Applicant[] = data?.data;
  const pageCount: number = info?.total_pages;

  // Funciones
  const handlePageChange = (selectedObject: { selected: number }): void => {
    setPage(selectedObject.selected);
  };

  const handleSize = (value: { value: number }): void =>
    setFilters({
      ...filters,
      size: value.value,
    });

  const handleOrder = (value: { value: number }): void =>
    setFilters({
      ...filters,
      order: value.value,
    });

  return (
    <div className={`aplicantslist ${theme}`}>
      <Filters setFilters={setFilters} theme={theme} />
      {isLoading && (
        <div className='loading'>
          <Lottie className='lottie' animationData={StatusLoading} loop />
        </div>
      )}

      {isError && (
        <div className='loading'>
          <Lottie className='lottie' animationData={Anim404} loop />
        </div>
      )}

      {isSuccess && (
        <>
          <ApplicantsItems applicants={applicants} setApplicantId={setApplicantId} />

          <motion.div
            transition={{ duration: 0.2 }}
            className={`pagination footer ${theme}`}
          >
            <article>
              <span>Cantidad:</span>
              <FiltersSelects
                name='size'
                large=''
                loading={false}
                isSearchable={false}
                menuPlacement='top'
                onChange={handleSize}
                options={sizeOptions}
                defaultValue={sizeOptions.find((item) => item.value === filters.size)}
              />
            </article>

            <Paginate
              info={info}
              pageCount={pageCount}
              currentPage={page}
              currentItems={applicants}
              handlePageChange={handlePageChange}
              itemsPerPage={filters.size}
            />

            <article>
              <span>Orden:</span>
              <FiltersSelects
                name='order'
                large=''
                loading={false}
                isSearchable={false}
                menuPlacement='top'
                onChange={handleOrder}
                options={orderOptions}
                defaultValue={orderOptions.find((item) => item.value === filters.order)}
              />
            </article>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default AplicantsList;
