/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { Controller, type UseFormHandleSubmit, type FieldValues } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';

// Contextos

// Hooks
import { useParentCategories, useCategories } from '../../hooks/useCategories';

// Componentes
import { actualDate } from '../../helpers/Dates';
import Markdown from '../Markdown';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  errors: FieldValues;
  control: any;
  register: any;
  theme: ThemeContextType['theme'];
  handleClick: () => void;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  createOffers: (body: string) => Promise<void>;
}
interface Category {
  id: number;
  label: string;
  options?: Category[];
  value?: boolean;
}
interface NewData {
  userId: string | null;
  content: string | null | undefined;
  active: boolean;
  categories: Array<boolean | undefined>;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const OffersForm = ({
  theme,
  errors,
  control,
  register,
  handleClick,
  handleSubmit,
  createOffers,
}: Props): JSX.Element => {
  // Estados

  const [groupedOptions, setGroupedOptions] = useState<Category[]>([]);

  const [statusSelected, setStatusSelected] = useState<{ value: boolean; label: string }>(
    {
      value: false,
      label: '🔴 Desactivado',
    }
  );

  const [categoriesSelected, setCategoriesSelected] = useState<Category[]>([]);

  const [contentData, setContentData] = useState<string | null | undefined>('');

  // Contextos

  // Hooks
  const parentCategories = useParentCategories();
  const categories = useCategories();

  // Funciones
  const userId = sessionStorage.getItem('userId');

  const statusRegistration = register('active', {
    required: `El estado es requerido.`,
  });

  const endDateRegistration = register('end_date', {
    required: `la fecha es requerida.`,
  });

  const titleRegistration = register('title', {
    required: `El titulo es requerido.`,
  });

  const categoriesRegistration = register('categories');

  const shortDescriptionRegistration = register('short_description', {
    required: `La descripcion es requerida.`,
    maxLength: {
      value: 80,
      message: 'La descripcion no puede tener más de 80 caracteres.',
    },
  });

  // const contentRegistration = register('content', {
  //   // required: `El contenido es requerido.`,
  // });

  const statusOptions = [
    { value: true, label: '🟢 Activo' },
    { value: false, label: '🔴 Desactivado' },
  ];

  const formatGroupLabel = (data: Category[] = groupedOptions): JSX.Element => (
    <div className={`groupselect ${theme}`}>
      <span className='groupselect__label'>{data[0]?.label}</span>
      <span className='groupselect__badge'>{data[0]?.options?.length}</span>
    </div>
  );

  const onSubmit = (data: FieldValues): void => {
    const newData: NewData = {
      ...data,
      userId,
      content: contentData,
      active: statusSelected.value,
      categories: categoriesSelected.map((category) => category.value),
    };
    void createOffers(JSON.stringify(newData));
    sessionStorage.setItem('updateCategories', 'true');
    handleClick();
  };

  // UseEffects
  useEffect(() => {
    if (categories.isSuccess) {
      groupedOptions.forEach((parent: { [x: string]: any; id: number }) => {
        categories.data?.data
          .filter((categorie: { parent: number }) => categorie.parent === parent.id)
          .forEach((categorie: { id: number; name: string }) =>
            parent.options.push({ value: categorie.id, label: categorie.name })
          );
      });
    }
  }, [groupedOptions, categories.isSuccess]);

  useEffect(() => {
    if (parentCategories.isSuccess) {
      const newGroupedOptions = parentCategories.data?.data.map(
        (item: { id: number; name: string }) => ({
          id: item.id,
          label: item.name,
          options: [],
        })
      );
      setGroupedOptions(newGroupedOptions);
    }
  }, [parentCategories.isSuccess]);

  // Renders
  return (
    <form className={`offersmodal ${theme}`} onSubmit={handleSubmit(onSubmit)}>
      <div className='offersmodal__header'>
        <h2>Crear Oferta:</h2>
        <button onClick={handleClick}>
          <AiOutlineCloseCircle />
        </button>
      </div>

      {/* Información basica */}
      <div className='offersmodal__row'>
        <div className='offersmodal__column'>
          <h3>Información básica:</h3>
          <p>Escriba la información básica sobre la oferta.</p>
        </div>

        <div className='offersmodal__form'>
          <div className='offersmodal__form__row'>
            <p>
              Estado:
              <Controller
                name='active'
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    isSearchable={false}
                    value={statusSelected}
                    options={statusOptions}
                    {...statusRegistration}
                    loadingMessage='Loading...'
                    classNamePrefix='offersmodal__select'
                    className={`offersmodal__select ${theme}`}
                    onChange={(value: { value: boolean; label: string }) => {
                      setStatusSelected(value);
                    }}
                  />
                )}
              />
            </p>

            <p>
              Fecha de finalización:
              <input
                type='date'
                id='end_date'
                name='end_date'
                min={actualDate()}
                defaultValue={actualDate()}
                className={errors.endDateRegistration ? 'error' : ''}
                {...endDateRegistration}
              />
              {errors.endDateRegistration && (
                <span role='alert'>{errors.endDateRegistration?.message}</span>
              )}
            </p>
          </div>

          <p>
            Categorias:
            <Controller
              name='categories'
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  {...categoriesRegistration}
                  isMulti
                  isSearchable={false}
                  options={groupedOptions}
                  value={categoriesSelected}
                  loadingMessage='Cargando...'
                  isLoading={groupedOptions.length}
                  formatGroupLabel={formatGroupLabel}
                  classNamePrefix='offersmodal__select'
                  className={`offersmodal__select ${theme}`}
                  onChange={(value: Category[]) => {
                    setCategoriesSelected(value);
                  }}
                />
              )}
            />
            {errors.categories && <span role='alert'>{errors.categories?.message}</span>}
          </p>

          <p>
            Título de la oferta:
            <input
              className={errors.title ? 'error' : ''}
              id='title'
              type='text'
              name='title'
              placeholder='Ej: Diseñador Senior Full Stack'
              {...titleRegistration}
            />
            {errors.title && <span role='alert'>{errors.title?.message}</span>}
          </p>
        </div>
      </div>

      {/* Descripcion de la oferta */}
      <div className='offersmodal__row'>
        <div className='offersmodal__column'>
          <h3>Descripción de la oferta:</h3>
          <p>
            Escriba una descripción resumida y otra más completa con lo que usted
            considere importante.
          </p>
        </div>

        <div className='offersmodal__form'>
          <p>
            Descripción general:
            <textarea
              rows='10'
              type='text'
              id='short_description'
              name='short_description'
              placeholder='Descripcion breve'
              {...shortDescriptionRegistration}
              className={errors.short_description ? 'error' : ''}
            />
            {errors.short_description && (
              <span role='alert'>{errors.short_description?.message}</span>
            )}
          </p>

          <p>
            Requerimientos:
            <Markdown
              theme={theme}
              prevContent=''
              contentData={contentData}
              setContentData={setContentData}
              // contentRegistration={contentRegistration}
            />
            {errors.content && <span role='alert'>{errors.content?.message}</span>}
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className='offersmodal__footer'>
        <button title='Cerrar modal' onClick={handleClick}>
          Cancelar
        </button>
        <button title='Enviar formulario' type='submit'>
          Publicar
        </button>
      </div>
    </form>
  );
};

export default OffersForm;
