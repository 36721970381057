// Librerias
import React, { useContext, type MouseEventHandler } from 'react';

import { BiMessageSquareEdit, BiTrash } from 'react-icons/bi';

// Contextos
import ThemeContext from '../context/ThemeContext';
import { type IdType } from '../types/ReactQuery';

interface Props {
  id: IdType;
  showOption: boolean;
  handleEdit: MouseEventHandler<HTMLButtonElement> | undefined;
  handleDelete: MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * Descripción: Componente personalizable para mostrar una lista de elementos en linea, sirve como dropdown o un modal pequeno
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Options = ({
  id,
  showOption,
  handleEdit,
  handleDelete,
}: Props): JSX.Element | null => {
  // Estados

  // Contextos
  const { theme } = useContext(ThemeContext);

  // UseEffects

  if (!showOption) {
    return null;
  }

  // Renders
  return (
    <>
      <div className={`options ${theme}`}>
        <button data-id={id} className='options__edit' onClick={handleEdit}>
          <BiMessageSquareEdit /> Editar
        </button>

        <button data-id={id} className='options__delete' onClick={handleDelete}>
          <BiTrash /> Eliminar
        </button>

        <div className='options__triangle' />
      </div>
    </>
  );
};

export default Options;
