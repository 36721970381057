/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
// Librerias
import { useContext } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseMutationResult
} from 'react-query';
import toast from 'react-hot-toast';
// Otros
import api from '../services/api.services';
import { KEY_COMMENTS } from '../constants/comments.constant';
import { type IdType } from '../types/ReactQuery';

interface CreateComment {
  newComment: UseMutationResult<any, unknown, string | Record<string, any>, unknown>;
  createComment: (body: string | Record<string, any>) => Promise<void>;
}

interface DeleteComment {
  deleteComment: UseMutationResult<any, unknown, IdType, unknown>;
  handleDelete: (id: IdType) => Promise<void>;
}

/**
 * Descripcion del hook: Este hook se encarga de las acciones en los comentarios
 *
 * Implementacion: Se llama al hook que necesita y se pasan los props correspondientes
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

export const useDeleteComment = (): DeleteComment => {
  const token = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const deleteComment = useMutation({
    mutationFn: async (id: IdType) => api.comments.deleteComment(token, id),
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_COMMENTS]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Eliminado correctamente!');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  const handleDelete = async (id: IdType): Promise<void> => {
    deleteComment.mutate(id);
  };

  return { deleteComment, handleDelete };
};

export const useCreateComment = (): CreateComment => {
  const token = sessionStorage.getItem('token');

  const queryClient = useQueryClient();

  const newComment = useMutation({
    mutationFn: async (body: string | Record<string, any>) => api.comments.createComment(token, body),
    onSuccess: (data) => {
      void queryClient.invalidateQueries([KEY_COMMENTS]);

      if (data.error) {
        toast.dismiss();
        toast.error('Algo ha ocurrido');
      } else {
        toast.dismiss();
        toast.success('Comentario publicado');
      }
    },

    onError: () => {
      toast.dismiss();
      toast.error('Algo ha ocurrido');
    }
  });

  const createComment = async (body: string | Record<string, any>): Promise<void> => {
    newComment.mutate(body);
  };

  return { newComment, createComment };
};

// const useComments = () => {
//   const token = sessionStorage.getItem('token');

//   return false;
// };

// export default useComments;
