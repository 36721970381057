import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ThemeContext from '../../context/ThemeContext';
import Users from './Users';
import Account from './Account';

const Settings = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>('users');
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const pathnameParts = location.pathname.split('/');
    const lastPathname = pathnameParts[pathnameParts.length - 1] !== 'account' ? "users" : "account";
    setSelectedTab(lastPathname);
  }, [location.pathname]);

  return (
    <>
      <section className={`settings ${theme}`}>
        <div className='settings__header'>
          <h2>Ajustes</h2>
        </div>

        <div className='settings__tabs'>
          <ul>
            <li
              className={selectedTab === 'users' ? 'selected' : ''}
              onClick={() => {
                navigate('/settings/users')
                
              }}
            >
              <p>Usuarios </p>
              {selectedTab === 'users' ? (
                <motion.div className='underline' layoutId='underline' />
              ) : null}
            </li>

            <li
              className={selectedTab === 'account' ? 'selected' : ''}
              onClick={() => {
                navigate('/settings/account')
              }}
            >
              <p>Cuenta </p>
              {selectedTab === 'account' ? (
                <motion.div className='underline' layoutId='underline' />
              ) : null}
            </li>
          </ul>
        </div>

        <AnimatePresence mode='wait'>
          <>
            <motion.div
              key={'empty'}
              className='settings__container'
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {selectedTab === 'users' && <Users theme={theme} />}
              {selectedTab === 'account' && <Account />}
            </motion.div>
          </>
        </AnimatePresence>
      </section>
    </>
  );
};

export default Settings;
